import React from "react"
import { Flex, Box, Heading, Divider, Text, UnorderedList, ListItem } from "@chakra-ui/react"

function Privacy() {
  return (
    <Flex p="1">
      <Box maxW="4xl" p="2">
        <Heading as="h1" size="xl" py="4">
          OperationsHero, Inc.
        </Heading>
        <Heading as="h3" size="lg" py="4">
          Privacy Policy
        </Heading>
        <Text>Last Modified: May 17th, 2021 </Text>
        <Heading as="h4" size="md" py="4">
          Introduction
        </Heading>
        <Text>OperationsHero, Inc. (“we,” “us,” or “our”) respects your privacy and is committed to protecting it through our compliance with this Policy. This Policy describes the types of information we may collect from you or that you may provide to us, including when you visit our website at OperationsHERO.com and any successor website or subdomain thereof (collectively, our “Website”) and when you use our cloud-based operations platform (our “Software”), and our practices for collecting, using, maintaining, protecting, and disclosing that information. We refer to our Website and our Software cumulatively as our “Services.”</Text>
        <Text>This Policy applies to any information we collect through our Services, including any information in any e-mails, posts, texts and other electronic messages between you and our Services, and any information about you transferred to our Services from other websites or third parties. This Policy also applies to any information we collect about you when you e-mail, text or exchange other electronic messages with us or when you interact with messages you receive from us, and any information we collect about you offline but that will be processed by automated means using our Services. This Policy does not apply to any other information we collect by any other means.</Text>
        <Text>Please read this Policy carefully to understand our policies and practices regarding your information and how we will treat it. If you do not agree with our policies and practices, you can choose not to use our Services. By accessing or using our Services or by otherwise sharing information with us, you agree to this Policy. This Policy may change from time to time. When we make changes to this Policy, we will post the updated Policy on our Website and update the Policy’s “last modified” date. We will also provide notice of any material changes as explained in the section below titled “Changes to Our Privacy Policy.” Your continued use of our Services after we make changes is deemed to be acceptance of those changes.</Text>

        <Heading as="h4" size="md" py="4">
          Information Collected About You and How It Is Collected
        </Heading>
        <Heading as="h4" size="sm" py="4">
          Information We Collect
        </Heading>
        <Text>We may collect several types of information from and about users of our Services, including information by which such individuals may be personally identified or information about such individuals that is maintained in a personally identifiable form (collectively, “Personal Information”). Specifically, we may collect:</Text>
        <UnorderedList>
          <ListItem>Identifiers, such as your name, postal address, phone number, e-mail address, IP address or mobile device ID, and account or user name. </ListItem>
          <ListItem>Payment information, such as credit card or debit card information, security codes, billing addresses and other financial information used to process transactions related to our Services.</ListItem>
          <ListItem>Employment-related information, including the name of your employer.</ListItem>
        </UnorderedList>
        <Heading as="h4" size="sm" py="4">
          Technical Information
        </Heading>
        <Text>As you navigate through and interact with our Services, we or our third-party service providers may automatically collect certain traffic data, usage information, and technical information (collectively, “Technical Information”) about your device, such as your device’s operating system and browser type, file information, time-stamped logs regarding access times and duration of visits, and the pages of our Website that you visit. The technologies we use for this automatic data collection may include cookies that are stored on your browser or device, small embedded electronic files known as web beacons (also referred to as clear gifs, pixel tags and single-pixel gifs) and other types of embedded code. We collect Technical Information to manage our content and to improve users’ use of our Services; to count and review the number of visitors to our Website and their usage patterns; to track key performance indicators such as pages accessed, frequency of access and other statistical information related to our Services (for example, verifying system and server integrity); to recognize if a visitor is a return visitor to our Website; to store information about a user’s visits; and to remember a user’s preferences. You can set your browser to refuse all or some browser cookies. If you choose not to accept cookies, you may be unable to access certain parts or pages of our Website, or certain parts or features of our Services may not function properly. To learn more about cookies and how to disallow or manage them, go to: www.allaboutcookies.org/.</Text>
        <Heading as="h4" size="sm" py="4">
          How We Collect Information
        </Heading>
        <Text>We primarily collect information, including Personal Information, directly from you when you create an account to use our Services, complete a purchase to use our Services, register to attend demonstrations, webinars, Q&As, online forums or similar informational events related to our Services, correspond with us via e-mail, text message, or telephone or through our Website, request additional services from us (such as customer support services), complete a survey or similar information-gathering form regarding our Services, or report a problem with our Services. We also collect certain information automatically as you use our Services or interact with the e-mails and other electronic messages we send to you (see section above titled “Technical Information”).</Text>

        <Heading as="h4" size="md" py="4">
          How We Use Your Information
        </Heading>
        <Heading as="h4" size="sm" py="4">
          Generally
        </Heading>
        <Text>We may use the information we collect about you or that you provide to us, including Personal Information, to: </Text>
        <UnorderedList>
          <ListItem>Provide our Services and their contents to you.</ListItem>
          <ListItem>Provide you with information, products or services you request from us.</ListItem>
          <ListItem>Process your purchases, transactions, and payments, including by transmitting certain of your Personal Information to the third-party payment processor that we use for securely processing payment card purchases, and to prevent transactional fraud.</ListItem>
          <ListItem>Create, maintain, customize, and secure your account with us.</ListItem>
          <ListItem>Provide you with support and to respond to your inquiries and customer service and support requests, including to investigate and address your concerns and to monitor and improve our responses.</ListItem>
          <ListItem>Help maintain the safety, security, and integrity of our Services, databases and other technology assets and our business.</ListItem>
          <ListItem>Perform research and development activities, which may include, for example, generating benchmarks and key performance indicators, conducting data analyses in order to develop new products and services or to improve existing products and services, and performing quality-control activities.</ListItem>
          <ListItem>Update and maintain customer, subscription and e-mail lists.</ListItem>
          <ListItem>Notify you about changes to our Services or this Policy.</ListItem>
          <ListItem>With respect to Technical Information, fulfill the purposes described in the section above titled “Technical Information.”</ListItem>
          <ListItem>Respond to lawful requests for information through court orders, subpoenas, warrants and other legal processes or obligations.</ListItem>
          <ListItem>Enforce any contracts between you and us, including for billing and collection, or to establish, exercise or defend legal claims.</ListItem>
          <ListItem>Evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of our assets, whether as a going concern or as part of a bankruptcy, liquidation, or similar proceeding.</ListItem>
          <ListItem>Fulfill any other purpose for which you provide the information.</ListItem>
          <ListItem>Serve any other purpose that we describe when you provide the information and for any other purpose with your consent. </ListItem>
        </UnorderedList>
        <Heading as="h4" size="sm" py="4">
          Use of Personal Information for Marketing Purposes
        </Heading>
        <Text>We may use your Personal Information to provide you with marketing and promotional information about our Services. If you do not wish to have your Personal Information used by us to contact you for marketing purposes, you can opt out by clicking on the “Unsubscribe” or similar link in the most recent e-mail you received from us and by following the prompts that appear. This opt-out does not apply to information provided as a result of a product purchase, product service experience or other transactions (e.g., an order confirmation). </Text>
        <Heading as="h4" size="sm" py="4">
          Aggregated or De-Identified Data
        </Heading>
        <Text>We may convert or combine some Personal Information of users into de-identified or aggregated data that does not disclose any of the Personal Information of any individual user. We may use and disclose to third parties any such de-identified or aggregated data (including traffic and usage data that does not identify you personally) for any lawful purpose.</Text>
        <Heading as="h4" size="sm" py="4">
          Tracking and “Do Not Track”{" "}
        </Heading>
        <Text>Tracking involves the use of cookies, web beacons, or other embedded code or tracking technologies to collect, analyze and store information on a user’s behavior over time on multiple sites, including information on the sites visited, products viewed, products purchased and other online interactions. Tracking information can be used to enable companies to make interest-based (behavioral) advertising available to users on multiple sites that they visit.</Text>
        <Text>We do not currently collect Personal Information about your online activities over time and across third-party sites for tracking purposes. However, other parties, including third-party advertisers, ad networks, ad servers, internet providers and mobile device providers, may use cookies, web beacons, or other embedded code or tracking technologies to collect Personal Information about your online activities over time and across third-party sites when you use our Services. We do not control these third parties’ tracking technologies or how they may be used. If you have any questions about an advertisement, you should contact the responsible advertiser directly. </Text>
        <Text>Our Services do not respond to any “do not track” signals sent by your computer, browser or mobile device, and if your computer, browser or mobile device sends a “do not track” signal to our Services, our Services will not treat you differently from users who do not send such signals. For more information on how to disable certain third-party tracking technologies, please visit the Network Advertising Initiative’s Consumer Opt-Out page at www.optout.networkadvertising.org/ and the Digital Advertising Alliance’s Consumer Assistance page at www.youradchoices.com/choices. </Text>
        <Heading as="h4" size="sm" py="4">
          Google Analytics
        </Heading>
        <Text>We use Google Analytics to track and analyze certain traffic and usage statistics regarding the use of our Website, such as the number of visitors to our Website, how visitors are navigating to our Website, visitors’ general geographic region information, how long individuals are visiting our Website, and information about the equipment individuals are using to access our Website (e.g., browser version and operating system). Google Analytics may use a Google Analytics cookie and may collect your IP address or mobile device ID as part of this service (but neither we nor Google Analytics otherwise collects or processes any of your Personal Information in connection with our use of the Google Analytics services). We use this traffic and usage information to gauge, internally, the effectiveness of our advertising efforts and to make improvements to our Website and our marketing and promotional efforts.</Text>
        <Text>In addition, from time to time we may use one or more of the following Google Analytics Advertising Features: </Text>
        <UnorderedList>
          <ListItem>Remarketing</ListItem>
          <ListItem>Google Display Network Impression Reporting</ListItem>
          <ListItem>Google Analytics Demographics and Interest Reporting</ListItem>
          <ListItem>Google Ads (an integrated service with Google Analytics)</ListItem>
        </UnorderedList>
        <Text>If these features are enabled, Google is permitted to collect certain additional traffic and usage data using a Google Advertising cookie and other identifiers not tied to your Personal Information. This information includes demographic information about our Website visitors and general information about our visitors’ interests, but does not identify our visitors individually. In fact, we are prohibited by Google policy from using this information to try to identify individual users and from merging non-personally identifiable information obtained from Google with Personal Information we otherwise have about you without obtaining your separate consent. These features are used to segment our audience and to better target our advertising and marketing efforts.</Text>
        <Text>For more information, please review the following:</Text>
        <UnorderedList>
          <ListItem>Information about Google’s use of cookies: www.policies.google.com/technologies/types </ListItem>
          <ListItem>Information about Google’s use of information from sites or apps that use Google’s services: www.policies.google.com/technologies/partner-sites</ListItem>
        </UnorderedList>
        <Text>You can opt out of our use of certain Google Analytics features by updating the “Ads Settings” in your browser (www.adssettings.google.com/authenticated) or mobile device (www.support.google.com/ads/answer/1660762#mob), by visiting the Network Advertising Initiative’s Consumer Opt-Out page at www.optout.networkadvertising.org/, or by visiting Google’s currently available opt-outs over the web at www.tools.google.com/dlpage/gaoptout/.</Text>
        <Heading as="h4" size="md" py="4">
          Disclosure of Your Information
        </Heading>
        <Text>We may disclose Personal Information to any contractors, service providers or other third parties who need to know such information to provide services that we request to support our permitted uses of your Personal Information. For example, we use a third-party cloud-hosting service provider to store certain of your Personal Information securely, we disclose certain of your Personal Information in connection with our use of Google Analytics (see the section above titled “Google Analytics”), we may disclose certain of your Personal Information to a third-party provider of customer relationship management services, and we may use a third-party payment processor to process your payments when you purchase a subscription to use our Software. </Text>
        <Text>We may also disclose your Personal Information to our affiliates or to a buyer or other successor to our business in the event of a sale of equity or assets, reorganization, merger, divestiture or a similar corporate transaction, whether as a going concern or as part of a bankruptcy, liquidation, or similar proceeding, and any due diligence review with respect to any of the foregoing transactions.</Text>
        <Text>In addition, we may disclose your Personal Information to comply with a court order or other legal process, including to meet law enforcement requirements or to respond to any government or regulatory request or audit, or to enforce or apply any contracts you have entered into with us (including our Online Subscription Agreement), or if we believe disclosure is necessary or appropriate to protect the rights, property, or safety of our customers or others. </Text>
        <Text>Finally, we may disclose your Personal Information for any other purpose disclosed when the information is provided and for any other purpose with your consent.</Text>
        <Heading as="h4" size="md" py="4">
          Users Outside of the United States
        </Heading>
        <Text>Our Website is hosted in the United States and is provided from the United States. It is possible that certain information will be stored on servers in multiple other countries on the “cloud” or other similar distributed hosting platforms. If you are a resident of any country other than the United States, please note that by providing your Personal Information to us, you are consenting to the transfer of your Personal Information to the United States and other jurisdictions and to our use and disclosure of your Personal Information in accordance with this Policy.</Text>
        <Heading as="h4" size="md" py="4">
          California “Shine The Light” Disclosure
        </Heading>
        <Text>The California Civil Code permits California residents with whom we have an established business relationship to request that we provide a list of certain categories of Personal Information that we have disclosed to third parties for their direct marketing purposes during the last 12 months. To make such a request, please send an e-mail to privacy@operationshero.com or otherwise contact us using the information set forth below. Please mention that you are making a “California Shine the Light” inquiry. However, please note that we do not currently disclose any of your Personal Information to third parties for their direct marketing purposes.</Text>
        <Heading as="h4" size="md" py="4">
          Nevada Residents
        </Heading>
        <Text>Nevada residents who wish to exercise their sale opt-out rights under Nevada Revised Statutes Chapter 603A may submit a request by contacting us at privacy@operationshero.com. Please note, however, that we do not currently sell any Personal Information to third parties within the meaning of such statute. </Text>
        <Heading as="h4" size="md" py="4">
          Third-Party Websites
        </Heading>
        <Text>Our Services may include links to blogs, social media and third-party websites. These third-party sites have their own privacy policies and terms of use and are not controlled by this Policy. You should carefully review any terms, conditions and policies of such third-party sites before visiting them or supplying them with any personal information. If you follow a link from our Services to any third-party site, any information you provide to that site will be governed by its own terms of use and privacy policy and not this Policy. </Text>
        <Text>We are not responsible for the privacy or security of any information you provide to a third-party website or the information practices used by any third-party site, including links to any third-party site from our Services. We make no representations, express or implied, concerning the accuracy, privacy, safety, security, or the information practices of any third-party site. The inclusion of a link to a third-party site on our Services does not constitute any type of endorsement of the linked site by us. We are not responsible for any loss or damage you may sustain resulting from your use of any third-party website or any information you share with a third-party website.</Text>
        <Heading as="h4" size="md" py="4">
          Children Under the Age of 16
        </Heading>
        <Text>Our Services are not intended for children under 16 years of age, and no one under the age of 16 may provide any information to us or others on or using our Services. We do not knowingly collect Personal Information from children under the age of 16. If you are under 16 years of age, do not use our Services or provide any information about yourself to us, including your name, address, telephone number, email address, IP address or mobile device ID, or any username you may use. If we learn we have collected or received Personal Information from a child under 16 years of age without verification of parental consent, we will delete that information. If you believe we might have any information from or about a child under the age of 16, please contact us at privacy@operationshero.com. </Text>
        <Heading as="h4" size="md" py="4">
          Data Security
        </Heading>
        <Text>We have implemented measures designed to secure your Personal Information from accidental loss and from unauthorized access, use, alteration and disclosure. However, the safety and security of your information also depends on you. When we have given you (or when you have chosen) a password for access to certain parts of our Services, you are responsible for keeping that password confidential. We ask you not to share your password with anyone. </Text>
        <Text>Unfortunately, the transmission of information over the internet is not completely secure. Although we have implemented security measures that we think are adequate, we cannot guarantee the security of your Personal Information transmitted to or using our Services. Any transmission of Personal Information is at your own risk. We are not responsible for the circumvention of any privacy settings or security measures contained on our Services. </Text>
        <Heading as="h4" size="md" py="4">
          Correcting Your Information
        </Heading>
        <Text>You may e-mail us at privacy@operationshero.com to request information about your Personal Information that we store and use and to request that we correct, amend or delete any such Personal Information. We will evaluate any requests we receive on a case-by-case basis but cannot guarantee that we will be able to accommodate your request. Some portions of your Personal Information may not be able to be amended or deleted except by also deleting your account. </Text>
        <Heading as="h4" size="md" py="4">
          Changes to Our Privacy Policy
        </Heading>
        <Text>We post any changes we make to our Policy on this page. If we make material changes to how we collect and use Personal Information, we will notify you by e-mail to the primary e-mail address specified in your account and/or through a notice on the Website home page. </Text>
        <Divider />
        <Heading as="h4" size="md" py="4">
          Contact Information
        </Heading>
        <Text>For any questions or concerns regarding your privacy, you may contact us using the following details: </Text>
        <Text>We are OperationsHero, Inc. To ask questions or to submit comments about this Policy and our privacy practices, you may contact us:</Text>
        <UnorderedList>
          <ListItem>Via E-mail: privacy@operationshero.com </ListItem>
          <ListItem>Via Postal Mail: OperationsHero, Inc., 1249 Kildaire Farm Road, Suite 160, Cary, NC 27511 </ListItem>
        </UnorderedList>
        <Divider />
      </Box>
    </Flex>
  )
}

export default Privacy
