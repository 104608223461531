import React from "react"
import { Box, Center, Heading, Img, SimpleGrid, Stack, Text, useColorModeValue as mode } from "@chakra-ui/react"
import { FaCopy, FaPlug, FaRecycle } from "react-icons/fa"
import { Feature } from "./Feature"
import SwitchImg from "../images/HeroHQ/AccountSettings_SwitchAccounts.JPG"

function Partners() {
  return (
    <Box as="section" w="100%" pt="16" pb="32">
      <Box
        maxW={{
          base: "xl",
          md: "7xl"
        }}
        mx="auto"
        px={{
          base: "6",
          md: "8"
        }}
      >
        <Heading size="2xl" textAlign="left" letterSpacing="tight" mb="5" fontWeight="extrabold" color={mode("blue.900", "blue.400")}>
          Partners / Contractors / Organizations
        </Heading>
        <Text fontSize="lg" color={mode("blackAlpha.800", "whiteAlpha.800")} maxW="2xl">
          One login to manage all of your different accounts!
        </Text>
        <Box mt="24">
          <SimpleGrid
            columns={{
              base: 1,
              md: 2
            }}
            spacing={{
              base: "16",
              md: "8"
            }}
          >
            <Stack spacing="12" maxW="lg">
              <Feature icon={<Box as={FaCopy} w="6" h="6" />} title="Vendor/Contract Management" color={mode("blue.600", "blue.400")}>
                Easily dispatch requests to your contractors for quotes or completion of work
              </Feature>
              <Feature icon={<Box as={FaPlug} w="6" h="6" />} title="One to Many Login" color={mode("blue.600", "blue.400")}>
                Quickly switch to different organizations with just 1 login for all of your organizations
              </Feature>
              <Feature icon={<Box as={FaRecycle} w="6" h="6" />} title="Savings automation" color={mode("blue.600", "blue.400")}>
                Manage expenses and track cost for vendors, contractors and partners
              </Feature>
            </Stack>
            <Center shadow="lg" minH="26rem">
              <Img objectFit="cover" w="full" h="full" src={SwitchImg} alt="Switch Accounts" />
            </Center>
          </SimpleGrid>
        </Box>
      </Box>
    </Box>
  )
}

export default Partners
