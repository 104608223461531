import React from "react"
import { VStack } from "@chakra-ui/react"
import { Outlet } from "react-router-dom"

import Navbar from "../Navbar/Navbar"
import Terms from "../Policy/Terms/Terms"
import Footer from "../Footer/Footer"

function TermsPage() {
  return (
    <VStack p={0}>
      <Navbar />
      <Outlet />
      <Terms />
      <Footer />
    </VStack>
  )
}

export default TermsPage
