import { Box, Img, Text, useColorModeValue } from "@chakra-ui/react" //HStack, Link, VisuallyHidden
import * as React from "react"
// import { FaTwitter, FaLinkedinIn } from "react-icons/fa"

export const Member = props => {
  const { image, name, children } = props //twitter, linkedIn
  return (
    <Box>
      <Box pos="relative">
        <Img pos="relative" alt={name} w="96" h="96" rounded="lg" objectFit="cover" src={image} zIndex="1" />
        <Box zIndex="0" pos="absolute" w="96" top="-2" left="2" h="100%" bg={useColorModeValue("blue.600", "blue.300")} rounded="lg" />
      </Box>
      <Text fontWeight="bold" mt="4" color={useColorModeValue("blue.900", "blue.400")}>
        {name}
      </Text>
      <Text mt="2" color={useColorModeValue("blackAlpha.800", "whiteAlpha.800")} maxW="20rem">
        {children}
      </Text>
      {/* <HStack mt="5" spacing="3">
        <Link isExternal color={useColorModeValue("blue.600", "blue.300")} href={twitter}>
          <VisuallyHidden>{`${name}'s Twitter page`}</VisuallyHidden>
          <FaTwitter aria-hidden />
        </Link>
        <Link isExternal color={useColorModeValue("blue.600", "blue.300")} href={linkedIn}>
          <VisuallyHidden>{`${name}'s Linkedin page`}</VisuallyHidden>
          <FaLinkedinIn aria-hidden />
        </Link>
      </HStack> */}
    </Box>
  )
}
