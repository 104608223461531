import React from "react"
import { useColorMode } from "@chakra-ui/color-mode"
import { Box, Button, Center, Heading, LightMode, SimpleGrid, Text, Badge } from "@chakra-ui/react"
//import HeroImage from "../images/maintenance.jpg"
import HeroImage from "../images/homePage.jpg"
import HeroImageDk from "../images/homePage1.jpg"
//import HeroImage from "../images/OperationsHERODM.png"

function Hero() {
  const { colorMode } = useColorMode()
  const isDark = colorMode === "dark"
  return (
    <Box
      w="100%"
      as="section"
      bg="blue.200"
      py="12"
      position="relative"
      h={{
        base: "480px",
        sm: "480px",
        md: "640px",
        lg: "900px"
      }}
      bgImage={isDark ? HeroImageDk : HeroImage}
      //bgGradient="linear(to-r, blue.200, blue.500)"
      //bgGradient={["linear(to-tr, teal.300, yellow.400)", "linear(to-t, blue.200, teal.500)", "linear(to-b, orange.100, purple.300)"]}
      bgSize="cover"
      bgPosition="center"
      _after={{
        content: `""`,
        display: "block",
        w: "full",
        h: "full",
        bg: "blackAlpha.700",
        position: "absolute",
        inset: 0,
        zIndex: 0
      }}
    >
      <Box
        //bgGradient="linear(to-r, blue.200, blue.500)"
        maxW={{
          base: "xl",
          md: "7xl"
        }}
        mx="auto"
        px={{
          base: "6",
          md: "8"
        }}
        h="full"
        zIndex={1}
        position="relative"
      >
        <Center flexDirection="column" textAlign="center" color="white" h="full">
          <Heading p="6" size="2xl" fontWeight="extrabold">
            Elevate Your Operations
          </Heading>
          <Text p="2" fontSize="lg" fontWeight="medium" mt="3">
            We offer solutions providing flexibility, speed, simplicity: All while being affordable!
          </Text>
          <LightMode>
            <Button p="6" as="a" href="/trial" colorScheme="blue" size="lg" mt="6" fontWeight="bold" fontSize="md">
              Sign Up for a Trial
            </Button>
            <Button p="6" as="a" href="/demo" colorScheme="purple" size="lg" mt="6" fontWeight="bold" fontSize="md">
              Sign Up for a Live Presentation
            </Button>
            <Button p="6" as="a" href="https://auth.operationshero.com/login" colorScheme="orange" size="lg" mt="6" fontWeight="bold" fontSize="md">
              Log in
            </Button>
          </LightMode>
        </Center>
      </Box>
      <Box
        display={{
          base: "none",
          md: "block"
        }}
        position="absolute"
        zIndex={2}
        w="full"
        bottom="0"
        py="2"
        bg="blackAlpha.800"
      >
        <Box
          maxW={{
            base: "xl",
            md: "7xl"
          }}
          mx="auto"
        >
          <SimpleGrid
            columns={{
              base: 1,
              md: 3
            }}
          >
            <Box textAlign="center" color="white">
              <Text fontSize="3xl">HeroHQ</Text>
              <Badge colorScheme="blue" variant="solid" fontSize="0.625rem">
                Multi-Workflows
              </Badge>
              <Text>Work/PM/Assets</Text>
            </Box>
            <Box textAlign="center" color="white">
              <Text fontSize="3xl">EventHQ</Text>
              <Badge colorScheme="blue" variant="solid" fontSize="0.625rem">
                Billing-Payments
              </Badge>
              <Text>Internal/External Events</Text>
            </Box>
            <Box textAlign="center" color="white">
              <Text fontSize="3xl">InventoryHQ</Text>
              <Badge colorScheme="blue" variant="solid" fontSize="0.625rem">
                QR Code-Barcodes
              </Badge>
              <Text>Check-In/Out/Supplies</Text>
            </Box>
            <Box textAlign="center" color="white">
              <Text fontSize="3xl">EnergyHQ</Text>
              <Badge colorScheme="blue" variant="solid" fontSize="0.625rem">
                Utilities
              </Badge>
              <Text>Energy Bill Analysis</Text>
            </Box>
            <Box textAlign="center" color="white">
              <Text fontSize="3xl">EventAutomation</Text>
              <Badge colorScheme="blue" variant="solid" fontSize="0.625rem">
                BAS Integration
              </Badge>
              <Text>Schedule Overrides</Text>
            </Box>
            <Box textAlign="center" color="white">
              <Text fontSize="3xl">PlanningHQ</Text>
              <Badge colorScheme="blue" variant="solid" fontSize="0.625rem">
                Capital
              </Badge>
              <Text>Submission to Completion</Text>
            </Box>
          </SimpleGrid>
        </Box>
      </Box>
    </Box>
  )
}

export default Hero
