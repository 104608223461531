import PatIMG from "../images/Headshot_Pat.jpg"
import ScottIMG from "../images/Headshot_Scott.jpg"
import StuIMG from "../images/Headshot_Stu.jpg"
import PaulIMG from "../images/Headshot_Paul.jpg"
import BobIMG from "../images/Headshot_Bob.jpg"
import PeachIMG from "../images/Headshot_Peach.jpg"

export const members = [
  {
    role: "President / Co-Founder",
    image: ScottIMG,
    name: "Scott Carpenter",
    description: "President"
  },
  {
    role: "CEO / Co-Founder",
    image: PatIMG,
    name: "Pat Buchanan",
    description: "Chief Executive Officer"
  },
  {
    role: "CTO / Co-Founder",
    image: StuIMG,
    name: "Stuart Eaves",
    description: "Cheif Technology Officer & Co-Founder"
  },
  {
    role: "Director of Client Services",
    image: PaulIMG,
    name: "Paul Fletcher",
    description: "ClientServices"
  },
  {
    role: "Operations Consultant / PapaHERO",
    image: BobIMG,
    name: "Bob Bittner",
    description: "PapaHERO."
  },
  {
    role: "Sales Leadership / BeAuSm",
    image: PeachIMG,
    name: "Josh Peach",
    description: "BeAuSm"
  }
]
