import React from "react"
import { VStack } from "@chakra-ui/react"
import { Outlet } from "react-router-dom"
import { HubspotProvider } from "@aaronhayes/react-use-hubspot-form"

import Navbar from "../Navbar/Navbar"
import Footer from "../Footer/Footer"
import DemoInventoryHQ from "../SignUps/Demo/DemoInventoryHQ"
import Solutions from "../Solutions/Solutions"

function DemoInventoryHQPage() {
  return (
    <VStack p={0}>
      <Navbar />
      <HubspotProvider>
        <DemoInventoryHQ />
      </HubspotProvider>
      <Solutions />
      <Outlet />
      <Footer />
    </VStack>
  )
}

export default DemoInventoryHQPage
