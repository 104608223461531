import React from "react"
import { useHubspotForm } from "@aaronhayes/react-use-hubspot-form"
import { Box, Flex, Heading, Img, SimpleGrid, Divider, Text, useColorModeValue as mode } from "@chakra-ui/react"
import { HiTemplate } from "react-icons/hi"
import { AiFillSetting } from "react-icons/ai"
import { RiTeamFill } from "react-icons/ri"
import { MdIntegrationInstructions } from "react-icons/md"
import { Feature } from "./Feature"
import LoginImg from "../../images/HeroHQ/LoginUpdated.JPG"

const FeatureImage = props => (
  <Box flex="1" {...props}>
    <Img objectFit="cover" h="75%" w="75%" src={LoginImg} alt="" />
  </Box>
)

function Renewal() {
  useHubspotForm({
    portalId: "9207294",
    formId: "17a7f303-5b55-405f-993e-9b90045a78d5",
    target: "#RenewalForm"
  })

  return (
    <Box as="section" w="100%" bg={mode("gray.25", "gray.800")} py="24">
      <Box
        maxW={{
          base: "xl",
          md: "7xl"
        }}
        mx="auto"
        px={{
          base: "6",
          md: "8"
        }}
      >
        <Flex
          justify="space-between"
          direction={{
            base: "column",
            lg: "row"
          }}
        >
          <Box
            maxW={{
              lg: "lg"
            }}
          >
            <Box
              mb={{
                lg: "8rem"
              }}
            >
              <Heading lineHeight="shorter" size="2xl" letterSpacing="tight" color={mode("blue.900", "white")} fontWeight="extrabold">
                <Box as="span" color={mode("blue.600", "blue.400")}>
                  View Live Presentation
                </Box>
              </Heading>
              <Text
                mt="4"
                fontSize="2xl"
                color={mode("gray.600", "gray.400")}
                maxW={{
                  lg: "md"
                }}
              >
                Tired of price increases with less support, no new features, limited mobile features and speed concerns! Sign Up below to view a live presentation:
                <Divider py="4" />
              </Text>
              <Text id="RenewalForm" fontSize="sm" mt="3" bg="blue.100" color="white" p="4" />
            </Box>
            <FeatureImage
              my={{
                base: "14",
                lg: "0"
              }}
              display={{
                base: "block",
                lg: "none"
              }}
            />
            <SimpleGrid
              flex="1"
              columns={{
                base: 1,
                md: 2
              }}
              spacing={{
                base: "3rem",
                md: "2rem"
              }}
            >
              <Feature title="Account Setup" icon={<AiFillSetting />}>
                Location, User, Asset/Equipment, Requests and Transaction Imports
              </Feature>
              <Feature title="APIs" icon={<MdIntegrationInstructions />}>
                REST APIs Available, Google and Microsoft Authentication, Push and Pull requests
              </Feature>
              <Feature title="Partners" icon={<RiTeamFill />}>
                Partners can help gather, cleanse and review data before migration
              </Feature>
              <Feature title="Dashboard/KPIs" icon={<HiTemplate />}>
                View key metrics for your organization and how you are comparing
              </Feature>
            </SimpleGrid>
          </Box>
          <FeatureImage
            maxW={{
              lg: "560px"
            }}
            display={{
              base: "none",
              lg: "block"
            }}
          />
        </Flex>
      </Box>
    </Box>
  )
}

export default Renewal
