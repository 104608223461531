import React from "react"
import { VStack } from "@chakra-ui/react"
import { Outlet } from "react-router-dom"

import Navbar from "../Navbar/Navbar"
import Footer from "../Footer/Footer"
import Solutions from "../Solutions/Solutions"

function SolutionsPage() {
  return (
    <VStack p={0}>
      <Navbar />
      <Outlet />
      <Solutions />
      <Footer />
    </VStack>
  )
}

export default SolutionsPage
