import RequestImg from "../../images/InventoryHQ/InventoryRequest.jpg"
import InvListImg from "../../images/InventoryHQ/InventoryList.jpg"
import InvCkOut from "../../images/InventoryHQ/InventoryCheckOut.jpg"
import InvLoc from "../../images/InventoryHQ/InventoryLocations.jpg"
import InvOrder from "../../images/InventoryHQ/InventoryOrder.jpg"
import InvAudit from "../../images/InventoryHQ/InventoryAudit.jpg"

export const members = [
  {
    role: "RequestForm",
    image: RequestImg,
    name: "Site Requests",
    twitter: "#",
    linkedin: "#",
    description: "Allow Sites to Request Inventory"
  },
  {
    role: "InventoryList",
    image: InvListImg,
    name: "Manage All Items",
    twitter: "#",
    linkedin: "#",
    description: "Search and Sort through Items"
  },
  {
    role: "CheckOut",
    image: InvCkOut,
    name: "Check In/Out",
    twitter: "#",
    linkedin: "#",
    description: "Check inventory in/out"
  },
  {
    role: "InventoryLocations",
    image: InvLoc,
    name: "Locations",
    twitter: "#",
    linkedin: "#",
    description: "Manage inventory in multiple locations"
  },
  {
    role: "Order",
    image: InvOrder,
    name: "Orders",
    twitter: "#",
    linkedin: "#",
    description: "Bulk Issue and ReStock inventory"
  },
  {
    role: "Audit",
    image: InvAudit,
    name: "Audits",
    twitter: "#",
    linkedin: "#",
    description: "Audit with QR/Bar Codes"
  }
]
