import { Box, Button, Divider, Heading, SimpleGrid, Text, useColorModeValue } from "@chakra-ui/react"
import * as React from "react"
import { Member } from "./Member"
import { members } from "./_data"
import { HiDocumentReport } from "react-icons/hi" //HiTemplate
import { FaFileInvoiceDollar } from "react-icons/fa"
import { AiFillSetting } from "react-icons/ai"
import { BiDirections } from "react-icons/bi"
import { RiTeamFill, RiDashboard2Fill } from "react-icons/ri"
import { MdIntegrationInstructions, MdLocationPin, MdOutlineCleaningServices } from "react-icons/md"
import { BsFillGridFill, BsPlusCircleFill, BsShieldLockFill, BsFillCalendar3WeekFill } from "react-icons/bs"
import { IoQrCodeSharp } from "react-icons/io5"
import { GoCopilot } from "react-icons/go"
import { Feature2 } from "./Feature2"

function HeroHQ() {
  return (
    <Box as="section" w="100%" bg={useColorModeValue("gray.50", "gray.700")}>
      <Box
        maxW="7xl"
        mx="auto"
        px={{
          base: "6",
          md: "8"
        }}
        py={{
          base: "12",
          md: "20"
        }}
      >
        <Heading size="2xl" letterSpacing="tight" mb="5" fontWeight="extrabold" color={useColorModeValue("blue.600", "blue.300")}>
          HeroHQ
        </Heading>
        <Text fontSize="lg" color={useColorModeValue("blackAlpha.800", "whiteAlpha.800")} maxW="2xl">
          We support the heroes in your operations. Our work order system supports your needs in Maintenance, Technology, Custodial, Grounds, Housing, Transportation, Athletics, Safety, Capital, Food Services, Events, Energy and more...
        </Text>
        {/* <Text fontSize="lg" my="10" color={useColorModeValue("blackAlpha.800", "whiteAlpha.800")} maxW="2xl"></Text> */}
        <Button as="a" href="/demo" colorScheme="orange" size="lg" mt="6" fontWeight="bold" fontSize="md">
          Sign Up for a Live Presentation
        </Button>
        <Divider my="10" />
        <SimpleGrid
          columns={{
            base: 2,
            md: 3
          }}
          mt="8"
          spacing="8"
        >
          <Feature2 icon={RiDashboard2Fill} title="Dashboard/Reports">
            Actionable KPIs & Metrics
          </Feature2>
          <Feature2 icon={RiTeamFill} title="Multiple Workflows">
            Maintenance, Technology, Custodial, etc.
          </Feature2>
          <Feature2 icon={GoCopilot} title="Assignments">
            Auto-Assign Teams/Technicians/Contractors
          </Feature2>
          <Feature2 icon={AiFillSetting} title="Schedule">
            PMs, Inspections, Audits, Safety Checks
          </Feature2>
          <Feature2 icon={IoQrCodeSharp} title="Assets">
            Scan Assets, Track Corrective vs PM
          </Feature2>
          <Feature2 icon={MdLocationPin} title="Location Capacities">
            Track exactly where issue occur
          </Feature2>
          <Feature2 icon={FaFileInvoiceDollar} title="Track Hours & Costs">
            Labor, Contractors, Expenses & Inventory
          </Feature2>
          <Feature2 icon={BsFillCalendar3WeekFill} title="Calendar">
            Drop & drag work from Month/Week/Day
          </Feature2>
          <Feature2 icon={MdOutlineCleaningServices} title="Enterprise Services">
            Multi-Account login & Contractor Role
          </Feature2>
          <Feature2 icon={BiDirections} title="Approval/Review">
            Approver & Reviewer Roles
          </Feature2>
          <Feature2 icon={MdIntegrationInstructions} title="Customization">
            Set Visibility & Required Rules by Role and Status
          </Feature2>
          <Feature2 icon={HiDocumentReport} title="Batch Update">
            By Status, Assignments, Categories, Custom Fields
          </Feature2>
          <Feature2 icon={BsFillGridFill} title="Mobile/Tablet Friendly">
            Works from any device and supports light/dark modes
          </Feature2>
          <Feature2 icon={BsPlusCircleFill} title="Integrations & APIs">
            Easily import data to get started
          </Feature2>
          <Feature2 icon={BsShieldLockFill} title="Private and Secure">
            Google/Microsoft Authentication, You Own Your Data!
          </Feature2>
        </SimpleGrid>
        <Divider my="10" />
        <SimpleGrid
          columns={{
            base: 1,
            md: 2,
            lg: 3
          }}
          spacingX="6"
          spacingY="16"
        >
          {members.map((member, idx) => (
            <Member key={idx} image={member.image} name={member.name} twitter="#" linkedIn="#">
              {member.description}
            </Member>
          ))}
        </SimpleGrid>
      </Box>
    </Box>
  )
}

export default HeroHQ
