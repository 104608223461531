import React from "react"
import { VStack } from "@chakra-ui/react"
import { Outlet } from "react-router-dom"

import Navbar from "../Navbar/Navbar"
import Footer from "../Footer/Footer"
import EnergyHQ from "../Solutions/EnergyHQ/EnergyHQ"

function EnergyHQPage() {
  return (
    <VStack p={0}>
      <Navbar />
      <Outlet />
      <EnergyHQ />
      <Footer />
    </VStack>
  )
}

export default EnergyHQPage
