import { Box, Button, Divider, Heading, SimpleGrid, Text, useColorModeValue } from "@chakra-ui/react"
import * as React from "react"
import { Member } from "./Member"
import { members } from "./_data"
import { Feature2 } from "./Feature2"
// import { HiTemplate, HiDocumentReport } from "react-icons/hi"
import { FaFileInvoiceDollar } from "react-icons/fa"
// import { AiFillSetting } from "react-icons/ai"
import { BiDirections, BiSolidTime } from "react-icons/bi"
// import { RiTeamFill } from "react-icons/ri"
import { MdShoppingCartCheckout, MdLocationPin, MdOutlineCleaningServices } from "react-icons/md" //MdIntegrationInstructions
import { BsFillGridFill, BsPlusCircleFill, BsShieldLockFill } from "react-icons/bs" //BsFillCalendar3WeekFill

function InventoryHQ() {
  return (
    <Box as="section" w="100%" bg={useColorModeValue("gray.50", "gray.700")}>
      <Box
        maxW="7xl"
        mx="auto"
        px={{
          base: "6",
          md: "8"
        }}
        py={{
          base: "12",
          md: "20"
        }}
      >
        <Heading size="2xl" letterSpacing="tight" mb="5" fontWeight="extrabold" color={useColorModeValue("blue.600", "blue.300")}>
          InventoryHQ
        </Heading>
        <Text fontSize="lg" color={useColorModeValue("blackAlpha.800", "whiteAlpha.800")} maxW="2xl">
          Manage Inventory for Maintenance, Custodial, Key/Lock, Transportation, Technology and many others. Check In/Out items used on Requests.
        </Text>
        {/* <Text fontSize="lg" my="10" color={useColorModeValue("blackAlpha.800", "whiteAlpha.800")} maxW="2xl"></Text> */}
        <Button as="a" href="/demoinventoryhq" colorScheme="orange" size="lg" mt="6" fontWeight="bold" fontSize="md">
          Sign Up for a Live Preview
        </Button>
        <Divider my="10" />
        <SimpleGrid
          columns={{
            base: 2,
            md: 3
          }}
          mt="8"
          spacing="8"
        >
          <Feature2 icon={FaFileInvoiceDollar} title="Track Costs">
            Save on Inventory Costs
          </Feature2>
          <Feature2 icon={BiDirections} title="Optimization">
            Request, Issue, Move, Audit, Restock
          </Feature2>
          <Feature2 icon={BiSolidTime} title="Save Time">
            Eliminate Paper Requests
          </Feature2>
          <Feature2 icon={MdShoppingCartCheckout} title="Check In/Out">
            Keys, Tools, Equipment
          </Feature2>
          <Feature2 icon={MdOutlineCleaningServices} title="Multi-Requests">
            Easily Request/Approve/Issue Items
          </Feature2>
          <Feature2 icon={MdLocationPin} title="Storage Locations">
            Warehouse, Vehicles, Closets
          </Feature2>
          <Feature2 icon={BsFillGridFill} title="Mobile/Tablet Friendly">
            Works from any device and supports light/dark modes
          </Feature2>
          <Feature2 icon={BsPlusCircleFill} title="Integrations & APIs">
            Easily import data to get started
          </Feature2>
          <Feature2 icon={BsShieldLockFill} title="Private and Secure">
            Google/Microsoft Authentication, You Own Your Data!
          </Feature2>
        </SimpleGrid>
        <Divider my="10" />
        <SimpleGrid
          columns={{
            base: 1,
            md: 2,
            lg: 3
          }}
          spacingX="6"
          spacingY="16"
        >
          {members.map((member, idx) => (
            <Member key={idx} image={member.image} name={member.name} twitter="#" linkedIn="#">
              {member.description}
            </Member>
          ))}
        </SimpleGrid>
      </Box>
    </Box>
  )
}

export default InventoryHQ
