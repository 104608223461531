import * as React from "react"
import { FaEnvelope, FaFacebook, FaLinkedin, FaTwitter, FaYoutube } from "react-icons/fa"

export const links = [
  {
    title: "Solution",
    links: [
      {
        label: "How it works",
        href: "/solutions"
      },
      {
        label: "Features",
        href: "/solutions"
      },
      {
        label: "Integrations",
        href: "/services"
      },
      {
        label: "Pricing",
        href: "/price"
      }
    ]
  },
  {
    title: "Policy",
    links: [
      {
        label: "Privacy Policy",
        href: "/privacy"
      },
      {
        label: "Website Terms of Service",
        href: "/website"
      },
      {
        label: "Online Subscription Agreement",
        href: "/terms"
      }
    ]
  },
  {
    title: "Support",
    links: [
      {
        label: "Phone",
        href: "tel:919.948.0842"
      },
      {
        label: "Email Support",
        href: "mailto:support@operationshero.com"
      },
      {
        label: "Our Story",
        href: "/support"
      },
      {
        label: "Partners",
        href: "/partners"
      }
    ]
  },
  {
    title: "Get Started",
    links: [
      {
        label: "Start for free",
        href: "https://auth.operationshero.com/signup"
      },
      {
        label: "Sign in",
        href: "https://auth.operationshero.com/login"
      },
      {
        label: "Live Presentation",
        href: "/demo"
      },
      {
        label: "Email Sales",
        href: "mailto:sales@operationshero.com"
      }
    ]
  }
]
export const socialLinks = [
  {
    label: "LinkedIn",
    icon: <FaLinkedin />,
    href: "//www.linkedin.com/company/operationshero"
  },
  {
    label: "Facebook",
    icon: <FaFacebook />,
    href: "//www.facebook.com/operationsHERO/"
  },
  {
    label: "Twitter",
    icon: <FaTwitter />,
    href: "//www.twitter.com/OperationsHero"
  },
  {
    label: "YouTube",
    icon: <FaYoutube />,
    href: "//www.youtube.com/channel/UCWxUYxQfNoJ3FxzaoV9ynQQ?view_as=subscriber"
  },
  {
    label: "Email",
    icon: <FaEnvelope />,
    href: "mailto:info@operationshero.com"
  }
]
export const footerLinks = [
  {
    label: "Terms of Service",
    href: "#"
  },
  {
    label: "Privacy Policy",
    href: "#"
  },
  {
    label: "Offer terms",
    href: "#"
  },
  {
    label: "Legal notice",
    href: "#"
  },
  {
    label: "Sitemap",
    href: "#"
  }
]
