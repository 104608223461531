import AnalyzeImg from "../../images/EnergyHQ/energy1.png"
import DiscoverImg from "../../images/EnergyHQ/energy2.png"
import EnergyStartImg from "../../images/EnergyHQ/energy3.png"
import aiImg from "../../images/EnergyHQ/energy4.png"
import TrendImg from "../../images/EnergyHQ/energy5.png"
import MeasureImg from "../../images/EnergyHQ/energy6.png"

export const members = [
  {
    role: "Analyze",
    image: AnalyzeImg,
    name: "Analyze Locations",
    twitter: "#",
    linkedin: "#",
    description: "Continuously compare & review locations"
  },
  {
    role: "Discover",
    image: DiscoverImg,
    name: "Discover Savings",
    twitter: "#",
    linkedin: "#",
    description: "Highlights areas of concern"
  },
  {
    role: "EnergyStar",
    image: EnergyStartImg,
    name: "EnergyStar",
    twitter: "#",
    linkedin: "#",
    description: "EnergyStar Integration"
  },
  {
    role: "AItech",
    image: aiImg,
    name: "Technology",
    twitter: "#",
    linkedin: "#",
    description: "Uses AI/ML technology"
  },
  {
    role: "Trend",
    image: TrendImg,
    name: "Trending",
    twitter: "#",
    linkedin: "#",
    description: "Trend data to spot errors"
  },
  {
    role: "Measure",
    image: MeasureImg,
    name: "Measure",
    twitter: "#",
    linkedin: "#",
    description: "Measure and report on savings"
  }
]
