import React from "react"
import { useHubspotForm } from "@aaronhayes/react-use-hubspot-form"
import { Box, Heading, SimpleGrid, Text, useColorModeValue as mode } from "@chakra-ui/react"
import { HiTemplate, HiDocumentReport } from "react-icons/hi"
import { FaFileInvoiceDollar } from "react-icons/fa"
// import { AiFillSetting } from "react-icons/ai"
import { BiDirections } from "react-icons/bi"
import { RiTeamFill } from "react-icons/ri"
import { MdIntegrationInstructions, MdLocationPin, MdOutlineCleaningServices } from "react-icons/md"
import { BsFillGridFill, BsPlusCircleFill, BsShieldLockFill, BsFillCalendar3WeekFill } from "react-icons/bs"
import { Feature2 } from "./Feature2"
// import LoginImg from "../../images/EventHQ/Events_Recur.JPG"

// const FeatureImage = props => (
//   <Box flex="1" {...props}>
//     <Img objectFit="contain" h="75%" w="75%" src={LoginImg} alt="" />
//   </Box>
// )

function DemoEventHQ() {
  useHubspotForm({
    portalId: "9207294",
    formId: "cbbbc85f-81e0-4a6d-9ed5-c54d91ce692f",
    target: "#DemoEventHQForm"
  })

  return (
    <Box
      as="section"
      py={{
        md: "12"
      }}
    >
      <Box
        bg={mode("gray.50", "gray.800")}
        maxW={{
          base: "xl",
          md: "7xl"
        }}
        mx="auto"
        px={{
          base: "6",
          md: "12",
          lg: "20"
        }}
        py={{
          base: "12",
          md: "20"
        }}
      >
        <SimpleGrid
          columns={{
            base: 1,
            md: 2
          }}
          spacing="10"
        >
          <Box>
            <Heading size="xl" mb="4" fontWeight="extrabold" color={mode("blue.600", "blue.400")}>
              EventHQ Preview/Feedback
            </Heading>
            <Text
              fontSize={{
                md: "lg"
              }}
              mb="6"
              maxW="md"
              color={mode("gray.600", "gray.400")}
            >
              Want to discuss what we are building for events? We would love your feedback on what we are designing and building. Sign Up below to view a live meeting:
            </Text>
            {/* <Button
              size="lg"
              colorScheme="blue"
              rightIcon={<BsArrowRight />}
              fontWeight="bold"
              fontSize="md"
              w={{
                base: "full",
                sm: "auto"
              }}
            >
              Learn more
            </Button> */}
          </Box>
          <Text id="DemoEventHQForm" fontSize="sm" mt="3" bg="blue.100" color="white" p="4" />
          {/* <Img
            htmlWidth="500px"
            htmlHeight="320px"
            height={{
              md: "320px"
            }}
            objectFit="cover"
            src="https://images.unsplash.com/photo-1534949752991-a065b0f5dfaa?ixid=MXwxMjA3fDB8MHxzZWFyY2h8MTYxfHxkZXZpY2V8ZW58MHx8MHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60"
            alt="state of the art speaker"
          /> */}
        </SimpleGrid>
        <SimpleGrid
          columns={{
            base: 1,
            md: 3
          }}
          mt="16"
          spacing="8"
        >
          <Feature2 icon={RiTeamFill} title="Group Management">
            Internal vs External, Group Type, Insurance, Contacts
          </Feature2>
          <Feature2 icon={MdLocationPin} title="Location Capacities">
            Track occupancy and room arrangements for each space
          </Feature2>
          <Feature2 icon={FaFileInvoiceDollar} title="Invoices Payments">
            Generate Invoices and receive payments online
          </Feature2>
          <Feature2 icon={BsFillCalendar3WeekFill} title="Calendar">
            Calendars to quickly view upcoming Events
          </Feature2>
          <Feature2 icon={MdOutlineCleaningServices} title="Services">
            What departments are needed for an event
          </Feature2>
          <Feature2 icon={HiTemplate} title="Equipment">
            Check Out Equipment for Events
          </Feature2>
          <Feature2 icon={BiDirections} title="Approvals">
            Approvals by Internal/External & Location/Room Types
          </Feature2>
          <Feature2 icon={MdIntegrationInstructions} title="Request Integration">
            Work Orders automatically generated through HeroHQ
          </Feature2>
          <Feature2 icon={HiDocumentReport} title="Dashboard KPIs/Metrics">
            View key metrics for your organization and how you are comparing
          </Feature2>
          <Feature2 icon={BsFillGridFill} title="Mobile/Tablet Friendly">
            Works from any device and supports Mobile/Tablet too
          </Feature2>
          <Feature2 icon={BsPlusCircleFill} title="Integrations & APIs">
            Easily import events, for conflict resolution
          </Feature2>
          <Feature2 icon={BsShieldLockFill} title="Private and Secure">
            Google/Microsoft Authentication, You Own Your Data!
          </Feature2>
        </SimpleGrid>
      </Box>
    </Box>
  )
}

export default DemoEventHQ
