import * as React from "react"
import { IoCalendar, IoGrid } from "react-icons/io5"
import { FaToolbox, FaMoneyCheck, FaThermometerHalf } from "react-icons/fa"
import { MdWeb } from "react-icons/md"

export const links = [
  {
    label: "Solutions",
    children: [
      {
        label: "HeroHQ",
        description: "Work, Assets, PM, Audits, Inspections & Safety",
        href: "/herohq",
        icon: <FaToolbox />
      },
      {
        label: "EventHQ",
        description: "Internal and External Events, Invoicing/Payments",
        href: "/eventhq",
        icon: <IoCalendar />
      },
      {
        label: "InventoryHQ",
        description: "Check-In/Out, Consumable Goods/Parts/Stock Supplies",
        href: "/inventoryhq",
        icon: <IoGrid />
      },
      {
        label: "EventAutomation",
        description: "BAS Schedule Overrides",
        href: "/eventauto",
        icon: <FaThermometerHalf />
      },
      {
        label: "PlanningHQ",
        description: "Manage and optimize your capital investments",
        href: "/projecthq",
        icon: <MdWeb />
      },
      {
        label: "EnergyHQ",
        description: "Energy Bill Analysis",
        href: "/energyhq",
        icon: <FaMoneyCheck />
      }
    ]
  },
  {
    label: "Services",
    href: "/services"
  },
  {
    label: "Partners",
    href: "/partners"
  },
  {
    label: "Support",
    href: "/support"
  }
]
