import React from "react"
import { VStack } from "@chakra-ui/react"
import { Outlet } from "react-router-dom"

import Navbar from "../Navbar/Navbar"
import Website from "../Policy/Website/Website"
import Footer from "../Footer/Footer"

function WebsitePage() {
  return (
    <VStack p={0}>
      <Navbar />
      <Outlet />
      <Website />
      <Footer />
    </VStack>
  )
}

export default WebsitePage
