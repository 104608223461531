import "./App.css"
import { VStack } from "@chakra-ui/react"
import { Outlet } from "react-router-dom"

// import Header from "./Components/Header/Header"
import Footer from "./Components/Footer/Footer"
import Hero from "./Components/Hero/Hero"
import Solutions from "./Components/Solutions/Solutions"
import Services from "./Components/Services/Services"
import Support from "./Components/Support/Support"
import Partners from "./Components/Partners/Partners"
import Navbar from "./Components/Navbar/Navbar"

function App() {
  return (
    <VStack p={0}>
      <Navbar />
      <Outlet />
      <Hero />
      <Solutions />
      <Services />
      <Support />
      <Partners />
      <Footer />
    </VStack>
  )
}

export default App
