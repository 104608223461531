import React from "react"
import { Box, Button, Flex, Heading, Img, SimpleGrid, Text, useColorModeValue as mode } from "@chakra-ui/react"
import { HiTemplate } from "react-icons/hi"
import { AiFillSetting } from "react-icons/ai"
import { RiTeamFill } from "react-icons/ri"
import { MdIntegrationInstructions } from "react-icons/md"
import { FaArrowRight } from "react-icons/fa"
import { Feature } from "./Feature"
import LoginImg from "../images/HeroHQ/LoginUpdated.JPG"

const FeatureImage = props => (
  <Box flex="1" {...props}>
    <Img objectFit="cover" h="100%" w="100%" src={LoginImg} alt="" />
  </Box>
)

function Services() {
  return (
    <Box as="section" w="100%" bg={mode("gray.25", "gray.800")} py="24">
      <Box
        maxW={{
          base: "xl",
          md: "7xl"
        }}
        mx="auto"
        px={{
          base: "6",
          md: "8"
        }}
      >
        <Flex
          justify="space-between"
          direction={{
            base: "column",
            lg: "row"
          }}
        >
          <Box
            maxW={{
              lg: "lg"
            }}
          >
            <Box
              mb={{
                lg: "8rem"
              }}
            >
              <Heading lineHeight="shorter" size="2xl" letterSpacing="tight" color={mode("blue.900", "white")} fontWeight="extrabold">
                {/* Professional <br /> */}
                <Box as="span" color={mode("blue.900", "blue.400")}>
                  Services
                </Box>
              </Heading>
              <Text
                mt="4"
                fontSize="2xl"
                color={mode("gray.600", "gray.400")}
                maxW={{
                  lg: "md"
                }}
              >
                Professional Services are available from our team and partners to onboard your solutions
              </Text>
              <Button
                className="group"
                mt="8"
                colorScheme="blue"
                as="a"
                href="/serviceinfo"
                size="lg"
                px="8"
                fontWeight="bold"
                h="14"
                iconSpacing="3"
                rightIcon={
                  <Box
                    as={FaArrowRight}
                    fontSize="sm"
                    transition="transform 0.2s"
                    _groupHover={{
                      transform: "translateX(2px)"
                    }}
                  />
                }
              >
                Learn More
              </Button>
            </Box>
            <FeatureImage
              my={{
                base: "14",
                lg: "0"
              }}
              display={{
                base: "block",
                lg: "none"
              }}
            />
            <SimpleGrid
              flex="1"
              columns={{
                base: 1,
                md: 2
              }}
              spacing={{
                base: "3rem",
                md: "2rem"
              }}
            >
              <Feature title="Account Setup" icon={<AiFillSetting />}>
                Location, User, Asset/Equipment, Requests and Transaction Imports
              </Feature>
              <Feature title="APIs" icon={<MdIntegrationInstructions />}>
                REST APIs Available, Google and Microsoft Authentication, Push and Pull requests
              </Feature>
              <Feature title="Partners" icon={<RiTeamFill />}>
                Partners can help gather, cleanse and review data before migration
              </Feature>
              <Feature title="Dashboard/KPIs" icon={<HiTemplate />}>
                View key metrics for your organization and how you are comparing
              </Feature>
            </SimpleGrid>
          </Box>
          <FeatureImage
            maxW={{
              lg: "560px"
            }}
            display={{
              base: "none",
              lg: "block"
            }}
          />
        </Flex>
      </Box>
    </Box>
  )
}

export default Services
