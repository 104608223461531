import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import "./index.css"
import App from "./App"
import { ChakraProvider, ColorModeScript } from "@chakra-ui/react"
import SolutionsPage from "./Components/Pages/SolutionsPage"
import ServicesPage from "./Components/Pages/ServicesPage"
import PartnersPage from "./Components/Pages/PartnersPage"
import SupportPage from "./Components/Pages/SupportPage"
import DemoPage from "./Components/Pages/DemoPage"
import TermsPage from "./Components/Pages/TermsPage"
import PrivacyPage from "./Components/Pages/PrivacyPage"
import CookiePage from "./Components/Pages/CookiePage"
import WebsitePage from "./Components/Pages/WebsitePage"
import AcceptableUsePage from "./Components/Pages/AcceptableUsePage"
import TrialPage from "./Components/Pages/TrialPage"
import LaunchPage from "./Components/Pages/LaunchPage"
import MobilePage from "./Components/Pages/MobilePage"
import PricePage from "./Components/Pages/PricePage"
import RenewalPage from "./Components/Pages/RenewalPage"
import SpeedPage from "./Components/Pages/SpeedPage"
import HeroHQPage from "./Components/Pages/HeroHQPage"
import EventAUTOPage from "./Components/Pages/EventAUTOPage"
import EventHQPage from "./Components/Pages/EventHQPage"
import InventoryHQPage from "./Components/Pages/InventoryHQPage"
import EnergyHQPage from "./Components/Pages/EnergyHQPage"
import ProjectHQPage from "./Components/Pages/ProjectHQPage"
import ServiceInfoPage from "./Components/Pages/ServiceInfoPage"
import DemoEventHQPage from "./Components/Pages/DemoEventHQPage"
import DemoInventoryHQPage from "./Components/Pages/DemoInventoryHQPage"
import DemoProjectHQPage from "./Components/Pages/DemoProjectHQPage"

const rootElement = document.getElementById("root")
ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider>
      <ColorModeScript initialColorMode="light"></ColorModeScript>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/solutions" element={<SolutionsPage />} />
          <Route path="/herohq" element={<HeroHQPage />} />
          <Route path="/eventhq" element={<EventHQPage />} />
          <Route path="/eventauto" element={<EventAUTOPage />} />
          <Route path="/inventoryhq" element={<InventoryHQPage />} />
          <Route path="/energyhq" element={<EnergyHQPage />} />
          <Route path="/projecthq" element={<ProjectHQPage />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/partners" element={<PartnersPage />} />
          <Route path="/support" element={<SupportPage />} />
          <Route path="/demo" element={<DemoPage />} />
          <Route path="/demoeventhq" element={<DemoEventHQPage />} />
          <Route path="/demoinventoryhq" element={<DemoInventoryHQPage />} />
          <Route path="/demoprojecthq" element={<DemoProjectHQPage />} />
          <Route path="/trial" element={<TrialPage />} />
          <Route path="/launch" element={<LaunchPage />} />
          <Route path="/serviceinfo" element={<ServiceInfoPage />} />
          <Route path="/price" element={<PricePage />} />
          <Route path="/mobile" element={<MobilePage />} />
          <Route path="/renewal" element={<RenewalPage />} />
          <Route path="/speed" element={<SpeedPage />} />
          <Route path="/terms" element={<TermsPage />} />
          <Route path="/privacy" element={<PrivacyPage />} />
          <Route path="/website" element={<WebsitePage />} />
          <Route path="/cookies" element={<CookiePage />} />
          <Route path="/acceptableUse" element={<AcceptableUsePage />} />
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  </React.StrictMode>,
  rootElement
)
