import React from "react"
import { Box, useColorModeValue as mode } from "@chakra-ui/react"
import { NavContent } from "./NavContent"

function Navbar() {
  return (
    <Box w="100%" bg={mode("gray.100", "gray.900")}>
      <Box as="header" bg={mode("white", "gray.800")} position="relative" zIndex="10">
        <Box
          as="nav"
          aria-label="Main navigation"
          maxW="7xl"
          mx="auto"
          px={{
            base: "6",
            md: "8"
          }}
        >
          <NavContent.Mobile
            display={{
              base: "flex",
              lg: "none"
            }}
          />
          <NavContent.Desktop
            display={{
              base: "none",
              lg: "flex"
            }}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default Navbar
