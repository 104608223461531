import RequestImg from "../../images/HeroHQ/NewRequest_ImagePreview.JPG"
import AssignedImg from "../../images/HeroHQ/StatusChangeModal_Queued.JPG"
import DashboardImg from "../../images/HeroHQ/DashboardMetrics.JPG"
import LaborImg from "../../images/HeroHQ/StatusChangeModal_Closed.JPG"
import ExpenseImg from "../../images/HeroHQ/Expenses_Supplies.JPG"
import FilterImg from "../../images/HeroHQ/List_RecentlyClosed.JPG"

export const members = [
  {
    role: "RequestForm",
    image: RequestImg,
    name: "Customizable Request Form",
    twitter: "#",
    linkedin: "#",
    description: "Turn fields on/off by Workflow with image previews!"
  },
  {
    role: "Assignment",
    image: AssignedImg,
    name: "Assign to Team/People",
    twitter: "#",
    linkedin: "#",
    description: "Assign Requests to Multiple Individuals or Teams"
  },
  {
    role: "DashboardMetrics",
    image: DashboardImg,
    name: "Dashboard Metrics",
    twitter: "#",
    linkedin: "#",
    description: "Metrics give you a quick overview or your operations"
  },
  {
    role: "Labor",
    image: LaborImg,
    name: "Labor",
    twitter: "#",
    linkedin: "#",
    description: "Technicians quickly track labor during completion of their requests."
  },
  {
    role: "Expenses",
    image: ExpenseImg,
    name: "Expenses",
    twitter: "#",
    linkedin: "#",
    description: "Easily track and manage expenses with receipts."
  },
  {
    role: "Filter",
    image: FilterImg,
    name: "Quick Filters",
    twitter: "#",
    linkedin: "#",
    description: "Filters allow you to quickly drill into your data for insights."
  }
]
