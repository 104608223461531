import { Box, Button, Divider, Heading, SimpleGrid, Text, useColorModeValue } from "@chakra-ui/react"
import * as React from "react"
import { Member } from "./Member"
import { members } from "./_data"

function EnergyHQ() {
  return (
    <Box as="section" w="100%" bg={useColorModeValue("gray.50", "gray.700")}>
      <Box
        maxW="7xl"
        mx="auto"
        px={{
          base: "6",
          md: "8"
        }}
        py={{
          base: "12",
          md: "20"
        }}
      >
        <Heading size="2xl" letterSpacing="tight" mb="5" fontWeight="extrabold" color={useColorModeValue("blue.600", "blue.300")}>
          EnergyHQ
        </Heading>
        <Text fontSize="lg" color={useColorModeValue("blackAlpha.800", "whiteAlpha.800")} maxW="2xl">
          Our solution helps clients act on both short and long-term improvement opportunities to lower utility bills, reduce operating costs, and improve occupant comfort and productivity.
        </Text>
        <Text fontSize="lg" my="10" color={useColorModeValue("blackAlpha.800", "whiteAlpha.800")} maxW="2xl">
          Provides real-time information on the pain points faced by building operators.
        </Text>
        <Button as="a" href="/demo" colorScheme="orange" size="lg" mt="6" fontWeight="bold" fontSize="md">
          Sign Up for a Live Presentation
        </Button>
        <Divider my="10" />
        <SimpleGrid
          columns={{
            base: 1,
            md: 2,
            lg: 3
          }}
          spacingX="6"
          spacingY="16"
        >
          {members.map((member, idx) => (
            <Member key={idx} image={member.image} name={member.name} twitter="#" linkedIn="#">
              {member.description}
            </Member>
          ))}
        </SimpleGrid>
      </Box>
    </Box>
  )
}

export default EnergyHQ
