import RequestImg from "../images/HeroHQ/NewRequest_ImagePreview.JPG"
import AssignedImg from "../images/HeroHQ/StatusChangeModal_Queued.JPG"
import DashboardImg from "../images/HeroHQ/DashboardMetrics.JPG"
import RequestInvImg from "../images/InventoryHQ/InventoryRequest.jpg"
import InvListImg from "../images/InventoryHQ/InventoryList.jpg"
import InvCkOut from "../images/InventoryHQ/InventoryCheckOut.jpg"
import EvList from "../images/EventHQ/EventList.jpg"
import EvDetails from "../images/EventHQ/EventDetails.jpg"
import EvCalendar from "../images/EventHQ/EventCalendar.jpg"

export const members = [
  {
    role: "RequestForm",
    image: RequestImg,
    name: "Customizable Request Form",
    twitter: "#",
    linkedin: "#",
    description: "Turn fields on/off by Workflow with image previews!"
  },
  {
    role: "Assignment",
    image: AssignedImg,
    name: "Assign to Team/People",
    twitter: "#",
    linkedin: "#",
    description: "Assign Requests to Multiple Individuals or Teams"
  },
  {
    role: "DashboardMetrics",
    image: DashboardImg,
    name: "Dashboard Metrics",
    twitter: "#",
    linkedin: "#",
    description: "Metrics give you a quick overview or your operations"
  },
  {
    role: "InvRequestForm",
    image: RequestInvImg,
    name: "Site Requests",
    twitter: "#",
    linkedin: "#",
    description: "Allow Sites to Request Inventory"
  },
  {
    role: "InventoryList",
    image: InvListImg,
    name: "Manage All Items",
    twitter: "#",
    linkedin: "#",
    description: "Search and Sort through Items"
  },
  {
    role: "CheckOut",
    image: InvCkOut,
    name: "Check In/Out",
    twitter: "#",
    linkedin: "#",
    description: "Check inventory in/out"
  },
  {
    role: "EventList",
    image: EvList,
    name: "Event List",
    twitter: "#",
    linkedin: "#",
    description: "Manage Events and needed Approvals"
  },
  {
    role: "EventDetails",
    image: EvDetails,
    name: "Event Details",
    twitter: "#",
    linkedin: "#",
    description: "Custom Approvals, Services and Equipment"
  },
  {
    role: "EventCalendar",
    image: EvCalendar,
    name: "Event Calendar",
    twitter: "#",
    linkedin: "#",
    description: "Month, Week, Day & Calendar Listings"
  }
]
