import React from "react"
import { Flex, Box, Heading, Divider, Text } from "@chakra-ui/react"

function Website() {
  return (
    <Flex p="1">
      <Box maxW="4xl" p="2">
        <Heading as="h1" size="xl" py="4">
          OperationsHero, Inc.
        </Heading>
        <Heading as="h3" size="lg" py="4">
          Website Terms of Service
        </Heading>
        <Text>Last Modified: May 17th, 2021 </Text>
        <Text>
          These terms and conditions (these “Terms of Service”) govern your use of OperationsHero, Inc.’s (“OperationsHERO,” “we,” “us” or “our”) family of websites, including www.operationshero.com and any successor website or subdomain thereof (collectively, the “Site”). Please read these Terms of Service carefully before using the Site. Compliance with these Terms of Service is a condition to your use of the Site. <b>By accessing and using the Site, you accept and agree to comply with these Terms of Service.</b> If you do not accept these Terms of Service, you are not permitted to, and you must not, access or use the Site.
        </Text>
        <Text>The Site is offered and available to users who are 18 years of age or older and who reside in the United States or Canada. If you are under the age of 18 or the applicable age of majority in your jurisdiction, then you represent that your parent or legal guardian has reviewed these Terms of Service carefully and has agreed to be bound by these Terms of Service on your behalf. If you are agreeing to be bound by these Terms of Service on behalf of your employer or another entity, you must have the full legal authority to bind your employer or such entity to these Terms of Service. By using the Site, you represent and warrant that you meet all of the foregoing eligibility requirements and that all information provided by you to us is truthful, accurate and complete. If you do not meet all of these requirements (as applicable), you must not access or use the Site.</Text>
        <Text>
          Please also consult our Privacy Policy (available online at www.operationshero.com/privacy) (our “Privacy Policy”) for a description of our privacy practices and policies with respect to information we collect on the Site. <b>By using the Site, you accept and agree to be bound and abide by our Privacy Policy. If you do not want to agree to our Privacy Policy</b>, you must not provide us any personal information.
        </Text>
        <Text>Our Site is hosted in the United States and is provided from the United States. It is possible that certain information will be stored on servers in multiple other countries on the “cloud” or other similar distributed hosting platforms. If you are a user accessing our Site from Europe, Asia, Canada or any other region with laws governing personal data collection, use, and disclosure that differ from United States laws, you are expressly and knowingly consenting to the transfer of your personal information to the United States and other jurisdictions as indicated above and to our use of your personal information in accordance with our Privacy Policy.</Text>
        <Text>OperationsHERO is based in the State of North Carolina in the United States. We provide the Site for use only by persons located in the United States and Canada, and we make no claims that the Site or any of its content is accessible or appropriate outside of the United States or Canada. Access to the Site may not be legal by certain persons or in certain countries. If you access the Site from outside the United States or Canada, you do so on your own initiative and are responsible for compliance with local laws.</Text>
        <Heading as="h4" size="md" py="4">
          1. Ownership and Use of the Site.
        </Heading>
        <Text>This Site and all of its Content (as defined below) are the property of OperationsHERO, its licensors or other providers of such materials and are protected by United States and international copyright, patent, trade secret, trademark and other intellectual property or proprietary rights laws. When accessing and viewing the Site in the form that it is provided to you over the internet, your device or your web browser may temporarily store or automatically cache copies of the content of the Site, including without limitation the information, software, files, documents, text, photographs, images, audio and video, and the design, selection and arrangement thereof, as well as any materials accessed through or made available for use or download through the Site (“Content”). The Content may not otherwise be copied, distributed, modified, adapted, translated, reproduced, published, republished, publicly displayed, publicly performed or used, in whole or in part, except that you may print or download a reasonable number of pages of the Site for your own personal, non-commercial use and/or internal business purposes and not for further reproduction, publication or distribution. You may not frame or utilize any other techniques to enclose, or link to, any name, trademark, service mark, logo, Content or other proprietary information (including images, text, page layout, or form) of OperationsHERO without our express written consent. These Terms of Service permit you to use the Site for your own personal, non-commercial use and/or your internal business purposes only. You must never delete or alter any copyright, trademark or other proprietary rights notices from copies of materials from the Site.</Text>
        <Text>The OperationsHERO name and logo, and all related names, logos, product and service names, designs and slogans are trademarks of OperationsHERO or its affiliates or licensors. You may not use such marks without the prior written permission of OperationsHERO. All other names, logos, product and service names, designs and slogans on the Site are the trademarks of their respective owners.</Text>
        <Text>You may use the Site only for lawful purposes and in accordance with these Terms of Service. You agree not to: (A) use the Site in any way that violates any applicable federal, state, local or international law or regulation; (B) use the Site in any manner that could disable, overburden, damage, or impair the Site or interfere with any other party’s use of the Site, including their ability to engage in real-time activities through the Site; (C) use any robot, spider or other automatic device, process or means to access the Site for any purpose, including monitoring or copying any of the Content on the Site; (D) introduce any viruses, trojan horses, worms, logic bombs or other material that is malicious or technologically harmful or attack the Site via a denial-of-service attack or a distributed denial-of-service attack; (E) attempt to gain unauthorized access to, interfere with, damage or disrupt any parts of the Site, the server on which the Site is stored, or any server, computer or database connected to the Site; or (F) engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of the Site or that may harm OperationsHERO or users of the Site or that may expose us or such users to liability.</Text>
        <Text>If you print, copy, modify, download or otherwise use or provide any other person with access to any part of the Site in breach of these Terms of Service, your right to use the Site will cease immediately and you must, at our option, return or destroy any copies of Content you have made. No right, title or interest in or to the Site or any Content is being transferred to you, and all rights not expressly granted are reserved by OperationsHERO. Any use of the Site not expressly permitted by these Terms of Service is a breach of these Terms of Service and may violate copyright, trademark and other laws.</Text>
        <Text>If you provide to us or to any of our affiliates any suggestions, designs or ideas for the Site or any software, products or services available on or through the Site or for enhancements or improvements to the Site or any software, products or services available on or through the Site (collectively, “Suggestions”), we and our affiliates will be entitled to use the Suggestions without restriction. You hereby irrevocably assign to us all right, title and interest in and to the Suggestions and agree to provide us any assistance we require to document, perfect and maintain our rights in the Suggestions.</Text>
        <Heading as="h4" size="md" py="4">
          2. Accuracy and Integrity of Information.
        </Heading>
        <Text>Although we attempt to ensure the integrity, completeness and accuracy of the Site, we make no representations, warranties or guarantees whatsoever as to the integrity, completeness or accuracy of the Site or any Content, and any reliance you place on such information is strictly at your own risk. OperationsHERO disclaims all liability and responsibility arising from any reliance placed on such materials by you or any other visitor to the Site, or by anyone who may be informed of any of its Content. It is possible that the Site could include typographical errors, inaccuracies or other errors, and that unauthorized additions, deletions and alterations could be made to the Site by third parties. In the event that an inaccuracy arises, please inform us so that the inaccuracy can be corrected.</Text>
        <Text>Information contained on the Site may be changed or updated without notice. Any Content on the Site may be out of date at any given time, and we are under no obligation to update such Content.</Text>
        <Text>The Site may include content provided by third parties, including materials provided by other users, bloggers and third-party licensors, syndicators, aggregators and/or reporting services (collectively, “Third-Party Materials”). All statements and/or opinions expressed in any Third-Party Materials, and all articles and responses to questions and other content, other than the Content provided by OperationsHERO, are solely the opinions and the responsibility of the person or entity providing the Third-Party Materials. Third-Party Materials do not necessarily reflect the opinion of OperationsHERO. OperationsHERO shall have no responsibility or liability for any Third-Party Materials posted to the Site.</Text>
        <Heading as="h4" size="md" py="4">
          3. Access to Site.{" "}
        </Heading>
        <Text>We reserve the right to withdraw or amend this Site and/or any Content we provide on this Site in our sole discretion without notice. We will not be liable if for any reason all or any part of the Site is unavailable at any time or for any period. Your access to the Site may be revoked or restricted by us at any time with or without cause and with or without notice.</Text>
        <Text>You are responsible for making all arrangements necessary for you to have access to the Site. To access certain portions of the Site, you may be asked to provide certain registration details or other information. You must provide information that is correct, current and complete. You agree that all information you provide to register with this Site or otherwise is governed by our Privacy Policy, and you consent to all actions we take with respect to your information consistent with our Privacy Policy.</Text>
        <Text>If you choose, or are provided with, a user name, password or any other piece of information as part of our security procedures, you must treat such information as confidential, and you must not disclose it to any other person or entity. You also acknowledge that your account is personal to you and agree not to provide any other person with access to the Site or portions of it using your user name, password or other security information. If you permit any other person to use your account, you will be responsible for their activities while using the Site. You agree to notify us immediately of any unauthorized access to or use of your user name or password or any other breach of security. We have the right to disable any user name, password or other identifier, whether chosen by you or provided by us, at any time in our sole discretion if, in our opinion, the continued use of that user name, password or other identifier would be inappropriate.</Text>
        <Heading as="h4" size="md" py="4">
          4. Platform
        </Heading>
        <Text>If you purchase a subscription to use our cloud-based operations platform (the “Platform”), you acknowledge and agree that your use of the Platform is also governed by our Online Subscription Agreement, the terms of which are hereby incorporated into these Terms of Service. In the event of a conflict between these Terms of Service and the Online Subscription Agreement, the Online Subscription Agreement shall control. </Text>
        <Heading as="h4" size="md" py="4">
          5. Links to Other Sites.
        </Heading>
        <Text>If the Site contains links to other websites and resources provided by third parties, these links are provided for your convenience only. OperationsHERO makes no representations or warranties (and shall have no liability) whatsoever about or with respect to any other website that you may access through this Site. When you access a non-OperationsHERO site, you understand and agree that it is independent from OperationsHERO and that OperationsHERO has no control over the content on that website. In addition, a hyperlink to a non-OperationsHERO website does not mean that OperationsHERO endorses or accepts any responsibility for the content, or the use, of the linked site. It is up to you to take precautions to ensure that whatever you select for your use or download is free of viruses, worms, Trojan horses, and other items of a destructive nature. If you decide to access any of the third-party sites linked to this Site, you do so entirely at your own risk and subject to the terms and conditions of use of such sites.</Text>
        <Heading as="h4" size="md" py="4">
          6. Governing Law and Jurisdiction.
        </Heading>
        <Text>All matters relating to the Site and these Terms of Service and any dispute or claim arising therefrom or related thereto (in each case, including non-contractual disputes or claims) shall be governed by and construed in accordance with the internal laws of the State of North Carolina, without giving effect to any conflict of laws rules (whether of the State of North Carolina or any other jurisdiction). </Text>
        <Text>Any legal suit, action or proceeding arising out of or related to the Site or these Terms of Service shall be instituted exclusively in the federal courts of the United States located in Wake County, North Carolina, or the courts of the State of North Carolina located in Wake County, North Carolina, except that we retain the right to bring any suit, action or proceeding against you for breach of these Terms of Service in your country of residence or any other relevant country. You hereby waive any and all objections to the exercise of jurisdiction over you by such courts and to venue in such courts.</Text>
        <Heading as="h4" size="md" py="4">
          7. DISCLAIMER OF WARRANTIES.{" "}
        </Heading>
        <Text>We cannot and do not guarantee or warrant that files available for downloading from the internet or the Site will be free of viruses or other destructive code. You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for anti-virus protection and accuracy of data input and output, and for maintaining a means external to our Site for any reconstruction of any lost data. WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE SITE OR ANY SOFTWARE, PRODUCTS OR SERVICES (INCLUDING THE PLATFORM) OBTAINED THROUGH THE SITE OR TO YOUR DOWNLOADING OF ANY CONTENT POSTED ON THE SITE, OR ON ANY WEBSITE LINKED TO IT.</Text>
        <Text>YOUR USE OF THE SITE, ITS CONTENT AND ANY SOFTWARE, PRODUCTS OR SERVICES (INCLUDING THE PLATFORM) OBTAINED THROUGH THE SITE IS AT YOUR OWN RISK. THE SITE, ITS CONTENT AND ANY SOFTWARE, PRODUCTS OR SERVICES (INCLUDING THE PLATFORM) OBTAINED THROUGH THE SITE ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER OPERATIONSHERO NOR ANY PERSON OR ENTITY ASSOCIATED WITH OPERATIONSHERO MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY OF THE SITE. WITHOUT LIMITING THE FOREGOING, NEITHER OPERATIONSHERO NOR ANY PERSON OR ENTITY ASSOCIATED WITH OPERATIONSHERO REPRESENTS OR WARRANTS THAT THE SITE, ITS CONTENT OR ANY SOFTWARE, PRODUCTS OR SERVICES (INCLUDING THE PLATFORM) OBTAINED THROUGH THE SITE WILL BE ACCURATE, RELIABLE, ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT THE SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE SITE OR ANY SOFTWARE, PRODUCTS OR SERVICES (INCLUDING THE PLATFORM) OBTAINED THROUGH THE SITE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.</Text>
        <Text>OPERATIONSHERO HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING, BUT NOT LIMITED TO, ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT AND FITNESS FOR A PARTICULAR PURPOSE.</Text>
        <Text>THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</Text>
        <Heading as="h4" size="md" py="4">
          8. Limitation on Time to File Claims.
        </Heading>
        <Text>ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OF SERVICE, THE SITE OR ANY SOFTWARE, PRODUCTS OR SERVICES (INCLUDING THE PLATFORM) OFFERED THROUGH THE SITE MUST BE COMMENCED WITHIN ONE YEAR AFTER THE CAUSE OF ACTION OR CLAIM ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED. </Text>
        <Heading as="h4" size="md" py="4">
          9. Limitation of Liability.
        </Heading>
        <Text>OPERATIONSHERO, ITS AFFILIATES AND THEIR RESPECTIVE LICENSORS, SERVICE PROVIDERS, EMPLOYEES, CONTRACTORS, AGENTS, MEMBERS, MANAGERS, OFFICERS, SHAREHOLDERS, DIRECTORS, SUCCESSORS AND ASSIGNS SHALL NOT BE RESPONSIBLE OR LIABLE FOR ANY (A) INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY OR PUNITIVE DAMAGES WHATSOEVER (INCLUDING, WITHOUT LIMITATION, THOSE RESULTING FROM LOST PROFITS, LOST DATA, LOSS OF GOODWILL OR BUSINESS INTERRUPTION), (B) DAMAGES FOR PERSONAL INJURY, PAIN AND SUFFERING OR EMOTIONAL DISTRESS, OR (C) DIRECT DAMAGES IN THE AGGREGATE IN EXCESS OF THE AMOUNTS YOU HAVE ACTUALLY PAID TO OPERATIONSHERO IN THE 12-MONTH PERIOD IMMEDIATELY PRECEDING THE LAST ACT OR OMISSION GIVING RISE TO SUCH LIABILITY, IN EACH CASE ARISING OUT OF OR RELATING IN ANY WAY TO THE SITE, SOFTWARE, PRODUCTS OR SERVICES (INCLUDING THE PLATFORM) OBTAINED THROUGH THE SITE, ANY CONTENT OR INFORMATION CONTAINED WITHIN THE SITE OR ANY HYPERLINKED WEBSITE, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), STATUTE, STRICT LIABILITY OR ANY OTHER LEGAL THEORY AND WHETHER OR NOT OPERATIONSHERO OR OTHERS WERE ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. YOUR SOLE REMEDY FOR DISSATISFACTION WITH THE SITE IS TO STOP USING THE SITE.</Text>
        <Text>SOME JURISDICTIONS DO NOT ALLOW CERTAIN LIMITATIONS OF LIABILITY, SO SOME OR ALL OF THE ABOVE LIMITATIONS OF LIABILITY MAY NOT APPLY TO YOU SOLELY TO THE EXTENT PROHIBITED BY APPLICABLE LAW.</Text>
        <Heading as="h4" size="md" py="4">
          10. Indemnification.
        </Heading>
        <Text>You agree to defend, indemnify and hold harmless OperationsHERO, its affiliates and their respective licensors, service providers, employees, contractors, agents, members, managers, officers, shareholders, directors, successors and assigns from and against any claims, liabilities, damages, judgments, settlements, awards, fines, losses, costs, expenses or fees (including reasonable attorneys’ fees) arising out of or relating to your violation of these Terms of Service or your use of the Site, including, but not limited to, any use of the Content or any software, products or services (including the Platform) available on the Site.</Text>
        <Heading as="h4" size="md" py="4">
          11. Revisions; General.
        </Heading>
        <Text>In the event that any provision of these Terms of Service is held by a court or other tribunal of competent jurisdiction to be unenforceable, such provision shall be limited or eliminated to the minimum extent necessary so that these Terms of Service shall otherwise remain in full force and effect. No waiver by OperationsHERO of any term or condition set forth in these Terms of Service shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure by OperationsHERO to assert a right or to enforce a provision under these Terms of Service shall not constitute a waiver of such right or provision. These Terms of Service, our Privacy Policy and, to the extent you have purchased a subscription to use our Platform, the Online Subscription Agreement constitute the entire agreement between OperationsHERO and you pertaining to the subject matter hereof. </Text>
        <Text>In its sole and absolute discretion, OperationsHERO may revise these Terms of Service from time to time by updating this page. Thus, you should periodically visit this page to review the current Terms of Service so you are aware of any such revisions to which you are bound. Your continued use of the Site following the posting of revised Terms of Service means that you accept and agree to the changes. The date these Terms of Service were last modified is listed at the beginning of these Terms of Service. Except as expressly provided in this paragraph, no amendment to or modification of these Terms of Service will be effective unless explicitly set forth in writing and signed by an authorized representative of OperationsHERO. </Text>
        <Heading as="h4" size="md" py="4">
          12. E-mail Subscriptions.
        </Heading>
        <Text>We take our communications with you seriously, and we offer you the option to unsubscribe from our marketing and promotional e-mails. In the event you choose to unsubscribe, we may still send you transactional communications via e-mail, such as responses to customer service requests and information about your purchases.</Text>
        <Divider />
        <Heading as="h4" size="md" py="4">
          13. Your Comments and Questions.
        </Heading>
        <Text>The Site is operated by OperationsHero, Inc., 1249 Kildaire Farm Road, Suite 160, Cary, North Carolina 27511. All feedback, comments, requests for technical support and other communications relating to the Site should be directed to: info@operationshero.com</Text>
      </Box>
    </Flex>
  )
}

export default Website
