import React from "react"
import { VStack } from "@chakra-ui/react"
import { Outlet } from "react-router-dom"

import Navbar from "../Navbar/Navbar"
import Footer from "../Footer/Footer"
import InventoryHQ from "../Solutions/InventoryHQ/InventoryHQ"

function InventoryHQPage() {
  return (
    <VStack p={0}>
      <Navbar />
      <Outlet />
      <InventoryHQ />
      <Footer />
    </VStack>
  )
}

export default InventoryHQPage
