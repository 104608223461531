import { Box, Button, Divider, Heading, SimpleGrid, Text, useColorModeValue } from "@chakra-ui/react"
import * as React from "react"
import { Feature2 } from "./Feature2"
// import { HiTemplate, HiDocumentReport } from "react-icons/hi"
import { FaFileInvoiceDollar } from "react-icons/fa"
// import { AiFillSetting } from "react-icons/ai"
import { BiDirections, BiSolidTime } from "react-icons/bi"
// import { RiTeamFill } from "react-icons/ri"
// import { MdIntegrationInstructions, MdLocationPin, MdOutlineCleaningServices } from "react-icons/md"
// import { BsFillGridFill, BsPlusCircleFill, BsShieldLockFill, BsFillCalendar3WeekFill } from "react-icons/bs"

function EventAUTO() {
  return (
    <Box as="section" w="100%" bg={useColorModeValue("gray.50", "gray.700")}>
      <Box
        maxW="7xl"
        mx="auto"
        px={{
          base: "6",
          md: "8"
        }}
        py={{
          base: "12",
          md: "20"
        }}
      >
        <Heading size="2xl" letterSpacing="tight" mb="5" fontWeight="extrabold" color={useColorModeValue("blue.600", "blue.300")}>
          EventAutomation
        </Heading>
        <Text fontSize="lg" color={useColorModeValue("blackAlpha.800", "whiteAlpha.800")} maxW="2xl">
          EventsHQ sends BAS Scheduling Overrides directly to Building Automation Systems (BAS)
        </Text>
        <Text fontSize="lg" my="10" color={useColorModeValue("blackAlpha.800", "whiteAlpha.800")} maxW="2xl">
          Uses BACnet and Niagara platform for multiple BAS Support
        </Text>
        <Button as="a" href="/demoeventhq" colorScheme="orange" size="lg" mt="6" fontWeight="bold" fontSize="md">
          Sign Up for a Live Preview
        </Button>
        <Divider my="10" />
        <SimpleGrid
          columns={{
            base: 2,
            md: 3
          }}
          mt="8"
          spacing="8"
        >
          <Feature2 icon={FaFileInvoiceDollar} title="Reduce Energy Costs">
            Save on Energy Costs
          </Feature2>
          <Feature2 icon={BiDirections} title="Optimization">
            Amend schedules based on actual usage
          </Feature2>
          <Feature2 icon={BiSolidTime} title="Save Time">
            Staff no longer has to manually create overrides
          </Feature2>
        </SimpleGrid>
      </Box>
    </Box>
  )
}

export default EventAUTO
