import PortalVenueImg from "../../images/EventHQ/portal_Venues.png"
import EventCalImg from "../../images/EventHQ/EventCalendar.jpg"
import PortalMinMaxImg from "../../images/EventHQ/portal_MinMax.png"
import RateSheetImg from "../../images/EventHQ/rateSheets.png"
import InvoiceImg from "../../images/EventHQ/invoices.png"
import RateGroupImg from "../../images/EventHQ/rateGroup.png"
import VenueImg from "../../images/EventHQ/venue_ApproversServiceEquipment.png"
import CustomCalImg from "../../images/EventHQ/calendar_Custom.png"
import CalListImg from "../../images/EventHQ/calendar_List.png"
import EventDates from "../../images/EventHQ/event_Block.png"
import EventRecur from "../../images/EventHQ/event_Recur.png"
import EventConflict from "../../images/EventHQ/event_Conflicts.png"

export const members = [
  {
    role: "EventDates",
    image: EventDates,
    name: "Easily Select Dates",
    twitter: "#",
    linkedin: "#",
    description: "Easily select available dates"
  },
  {
    role: "EventRecur",
    image: EventRecur,
    name: "Recurring Events",
    twitter: "#",
    linkedin: "#",
    description: "Days, Weeks, Months and Yearly Schedules"
  },
  {
    role: "EventConflict",
    image: EventConflict,
    name: "Conflict Checking",
    twitter: "#",
    linkedin: "#",
    description: "Bulk edit events to resolve conflicts"
  },
  {
    role: "Calendar",
    image: CalListImg,
    name: "Internal/External Calendar",
    twitter: "#",
    linkedin: "#",
    description: "Month/Week/Day views for Site/Venue Calendars"
  },
  {
    role: "Calendar",
    image: EventCalImg,
    name: "Internal/External Calendar",
    twitter: "#",
    linkedin: "#",
    description: "Month/Week/Day views for Site/Venue Calendars"
  },
  {
    role: "CalendarCustom",
    image: CustomCalImg,
    name: "Calendar Inegration",
    twitter: "#",
    linkedin: "#",
    description: "Subscription Feed for your Locations or Space"
  },
  {
    role: "PortalVenue",
    image: PortalVenueImg,
    name: "Community Portal",
    twitter: "#",
    linkedin: "#",
    description: "Venues with Services, Equipment, Uses & Quotes"
  },
  {
    role: "PortalLimits",
    image: PortalMinMaxImg,
    name: "Limit Min/Max Dates",
    twitter: "#",
    linkedin: "#",
    description: "Restrict minimum and maximum event from portal"
  },
  {
    role: "ServiceEquip",
    image: VenueImg,
    name: "Service & Equipment",
    twitter: "#",
    linkedin: "#",
    description: "Custom services and equipment for Venues"
  },
  {
    role: "RateGroup",
    image: RateGroupImg,
    name: "Rate Groups",
    twitter: "#",
    linkedin: "#",
    description: "Custom Rate Groups"
  },
  {
    role: "RateSheetImg",
    image: RateSheetImg,
    name: "Rate Sheets",
    twitter: "#",
    linkedin: "#",
    description: "Location, Service, Equipment & Event Fees"
  },
  {
    role: "InvoiceImg",
    image: InvoiceImg,
    name: "Invoice/Payments",
    twitter: "#",
    linkedin: "#",
    description: "Quotes, Invoices, & Payments"
  }
]
