import React from "react"
import { VStack } from "@chakra-ui/react"
import { Outlet } from "react-router-dom"

import Navbar from "../Navbar/Navbar"
import AcceptableUse from "../Policy/AcceptableUse/AcceptableUse"
import Footer from "../Footer/Footer"

function AcceptableUsePage() {
  return (
    <VStack p={0}>
      <Navbar />
      <Outlet />
      <AcceptableUse />
      <Footer />
    </VStack>
  )
}

export default AcceptableUsePage
