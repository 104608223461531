import React from "react"
import { Box, Button, Center, Divider, Heading, Img, SimpleGrid, Stack, Text, useColorModeValue as mode, Link, Avatar } from "@chakra-ui/react"
import { FaArrowRight, FaFileSignature, FaHandsHelping, FaHeadset } from "react-icons/fa"
import { Feature } from "./Feature"
import ProfileImg from "../images/HeroHQ/AccountSettings_Profile.JPG"
import { members } from "./members"

function Support() {
  return (
    <Box as="section" w="100%" py="10" bg={mode("gray.50", "gray.700")}>
      <Box
        maxW={{
          base: "xl",
          md: "7xl"
        }}
        mx="auto"
        px={{
          base: "6",
          md: "8"
        }}
      >
        <Stack
          spacing={{
            base: "4",
            lg: "20"
          }}
          direction={{
            base: "column",
            lg: "row"
          }}
        >
          <Center
            flex="1"
            shadow="lg"
            minH="26rem"
            maxW={{
              lg: "xl"
            }}
          >
            <Img objectFit="cover" w="full" h="full" htmlWidth="576px" htmlHeight="420px" src={ProfileImg} alt="Suport" />
          </Center>
          <Box
            maxW={{
              lg: "lg"
            }}
          >
            <Heading size="2xl" mt="10" color={mode("blue.600", "blue.400")} fontWeight="extrabold" letterSpacing="tight" lineHeight="normal">
              Support
            </Heading>
            <Text fontSize="lg" mt="6" color={mode("gray.600", "gray.400")}>
              Our support team is led by Paul Fletcher, the Director of Client Services. Paul has over 15 years of implementation, client success, and support leadership. He was mentored by the late legendary client advocate, Joan Warren Maddox. The team has over 100 combined years of experience in education operations. We have worked onsite with higher education, school districts, private schools, clubs, states, and government organizations in all 50 states and Canadian provinces.
            </Text>
            <Button
              className="group"
              mt="8"
              colorScheme="blue"
              onClick={event => (window.location.href = "mailto:support@operationshero.com")}
              size="lg"
              px="8"
              fontWeight="bold"
              h="14"
              iconSpacing="3"
              rightIcon={
                <Box
                  as={FaArrowRight}
                  fontSize="sm"
                  transition="transform 0.2s"
                  _groupHover={{
                    transform: "translateX(2px)"
                  }}
                />
              }
            >
              EMail Support
            </Button>
          </Box>
        </Stack>
        <Divider my="20" opacity={1} />
        <SimpleGrid
          columns={{
            base: 1,
            md: 3
          }}
          spacing={{
            base: "12",
            md: "8"
          }}
        >
          <Feature title="Phone Support" icon={<FaHeadset />}>
            Live phone support available for all clients:{" "}
            <Link href="tel:919.948.0842" isExternal>
              919.948.0842
            </Link>
          </Feature>
          <Feature title="Email Support" icon={<FaFileSignature />}>
            Prefer to email our team:{" "}
            <Link href="mailto:support@operationshero.com" isExternal>
              support@operationshero.com
            </Link>
          </Feature>
          <Feature title="Collaborate with partners" icon={<FaHandsHelping />}>
            Need help collecting PM, Assets or Capital:{" "}
            <Link href="mailto:partners@operationshero.com" isExternal>
              partners@operationshero.com
            </Link>
          </Feature>
        </SimpleGrid>
        <Divider my="20" opacity={1} />
      </Box>
      <SimpleGrid
        maxW={{
          base: "xl",
          md: "7xl"
        }}
        mx="auto"
        px={{
          base: "6",
          md: "8"
        }}
      >
        <Stack
          spacing={{
            base: "12",
            md: "16"
          }}
        >
          <Stack spacing="3" align="center" textAlign="center">
            <Stack
              spacing={{
                base: "4",
                md: "5"
              }}
            >
              <Heading size="2xl" mt="10" color={mode("blue.600", "blue.400")} fontWeight="extrabold" letterSpacing="tight" lineHeight="normal">
                Leadership Team
              </Heading>
            </Stack>
          </Stack>
          <SimpleGrid
            columns={{
              base: 1,
              sm: 2,
              md: 2,
              lg: 3
            }}
            columnGap="4"
            rowGap={{
              base: "10",
              lg: "16"
            }}
          >
            {members.map(member => (
              <Stack key={member.name} spacing="4" align="center" textAlign="center">
                <Stack
                  spacing={{
                    base: "4",
                    md: "4"
                  }}
                  align="center"
                >
                  <Avatar
                    src={member.image}
                    boxSize={{
                      base: "24",
                      lg: "32"
                    }}
                  />
                  <Box>
                    <Text fontWeight="medium" fontSize="xl" color={mode("blue.500", "blue.300")}>
                      {member.name}
                    </Text>
                    <Text color="accent">{member.role}</Text>
                  </Box>
                </Stack>
              </Stack>
            ))}
          </SimpleGrid>
        </Stack>
      </SimpleGrid>
    </Box>
  )
}

export default Support
