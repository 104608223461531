import { Box, Button, Divider, Heading, SimpleGrid, Text, useColorModeValue } from "@chakra-ui/react"
import * as React from "react"
import { Member } from "./Member"
import { members } from "./_data"
import { HiTemplate, HiDocumentReport } from "react-icons/hi"
import { FaFileInvoiceDollar } from "react-icons/fa"
// import { AiFillSetting } from "react-icons/ai"
import { BiDirections } from "react-icons/bi"
import { RiTeamFill } from "react-icons/ri"
import { MdIntegrationInstructions, MdLocationPin, MdOutlineCleaningServices } from "react-icons/md"
import { BsFillGridFill, BsPlusCircleFill, BsShieldLockFill, BsFillCalendar3WeekFill } from "react-icons/bs"
import { Feature2 } from "./Feature2"

function EventHQ() {
  return (
    <Box as="section" w="100%" bg={useColorModeValue("gray.50", "gray.700")}>
      <Box
        maxW="7xl"
        mx="auto"
        px={{
          base: "6",
          md: "8"
        }}
        py={{
          base: "12",
          md: "20"
        }}
      >
        <Heading size="2xl" letterSpacing="tight" mb="5" fontWeight="extrabold" color={useColorModeValue("blue.600", "blue.300")}>
          EventHQ
        </Heading>
        <Text fontSize="lg" color={useColorModeValue("blackAlpha.800", "whiteAlpha.800")} maxW="2xl">
          Complete Event Management for any size Organization. Manage your Groups, Contacts, Insurance, Billing, Calendars, Internal/Exteral Events, Automatic Work Order Creation
        </Text>
        {/* <Text fontSize="lg" my="10" color={useColorModeValue("blackAlpha.800", "whiteAlpha.800")} maxW="2xl"></Text> */}
        <Button as="a" href="/demoeventhq" colorScheme="orange" size="lg" mt="6" fontWeight="bold" fontSize="md">
          Sign Up for a Live Preview
        </Button>
        <Divider my="10" />
        <SimpleGrid
          columns={{
            base: 2,
            md: 3
          }}
          mt="8"
          spacing="8"
        >
          <Feature2 icon={RiTeamFill} title="Group Management">
            Internal vs External, Group Type, Insurance, Contacts
          </Feature2>
          <Feature2 icon={MdLocationPin} title="Location Capacities">
            Track occupancy and room arrangements for each space
          </Feature2>
          <Feature2 icon={FaFileInvoiceDollar} title="Invoices Payments">
            Generate Invoices and receive payments online
          </Feature2>
          <Feature2 icon={BsFillCalendar3WeekFill} title="Calendar">
            Calendars to quickly view upcoming Events
          </Feature2>
          <Feature2 icon={MdOutlineCleaningServices} title="Services">
            What departments are needed for an event
          </Feature2>
          <Feature2 icon={HiTemplate} title="Equipment">
            Check Out Equipment for Events
          </Feature2>
          <Feature2 icon={BiDirections} title="Approvals">
            Approvals by Internal/External & Location/Room Types
          </Feature2>
          <Feature2 icon={MdIntegrationInstructions} title="Request Integration">
            Work Orders automatically generated through HeroHQ
          </Feature2>
          <Feature2 icon={HiDocumentReport} title="Dashboard KPIs/Metrics">
            View key metrics for your organization and how you are comparing
          </Feature2>
          <Feature2 icon={BsFillGridFill} title="Mobile/Tablet Friendly">
            Works from any device and supports Mobile/Tablet too
          </Feature2>
          <Feature2 icon={BsPlusCircleFill} title="Integrations & APIs">
            Easily import events, for conflict resolution
          </Feature2>
          <Feature2 icon={BsShieldLockFill} title="Private and Secure">
            Google/Microsoft Authentication, You Own Your Data!
          </Feature2>
        </SimpleGrid>
        <Divider my="10" />
        <SimpleGrid
          columns={{
            base: 1,
            md: 2,
            lg: 3
          }}
          spacingX="6"
          spacingY="16"
        >
          {members.map((member, idx) => (
            <Member key={idx} image={member.image} name={member.name} twitter="#" linkedIn="#">
              {member.description}
            </Member>
          ))}
        </SimpleGrid>
      </Box>
    </Box>
  )
}

export default EventHQ
