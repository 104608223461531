import React from "react"
import { VStack } from "@chakra-ui/react"
import { Outlet } from "react-router-dom"

import Navbar from "../Navbar/Navbar"
import Footer from "../Footer/Footer"
import EventHQ from "../Solutions/EventHQ/EventHQ"

function EventHQPage() {
  return (
    <VStack p={0}>
      <Navbar />
      <Outlet />
      <EventHQ />
      <Footer />
    </VStack>
  )
}

export default EventHQPage
