import React from "react"
import { VStack } from "@chakra-ui/react"
import { Outlet } from "react-router-dom"

import Navbar from "../Navbar/Navbar"
import Cookie from "../Policy/Cookie/Cookie"
import Footer from "../Footer/Footer"

function CookiePage() {
  return (
    <VStack p={0}>
      <Navbar />
      <Outlet />
      <Cookie />
      <Footer />
    </VStack>
  )
}

export default CookiePage
