import { Box, Button, Flex, HStack, useDisclosure, IconButton, VisuallyHidden, Image, useColorModeValue as mode } from "@chakra-ui/react"
import * as React from "react"
import { useColorMode } from "@chakra-ui/color-mode"
// import { Logo } from "./Logo"
import { NavLink } from "./NavLink"
import { NavMenu } from "./NavMenu"
import { Submenu } from "./Submenu"
import { ToggleButton } from "./ToggleButton"
import { links } from "./_data"
import { FaSun, FaMoon } from "react-icons/fa"
import OHcolor from "../images/HeroHQ/OH_FinalLogo_ColorRGB.svg"
import OHdark from "../images/HeroHQ/OH_FinalLogo_White.svg"

const MobileNavContext = props => {
  const { colorMode, toggleColorMode } = useColorMode()
  const isDark = colorMode === "dark"
  const { isOpen, onToggle } = useDisclosure()
  return (
    <>
      <Flex align="center" justify="space-between" className="nav-content__mobile" {...props}>
        <Box flexBasis="6rem">
          <ToggleButton isOpen={isOpen} onClick={onToggle} />
        </Box>
        <Box as="a" href="/" rel="home" mx="auto">
          <Image h="12" src={isDark ? OHdark : OHcolor} alt="OperationsHERO" />
          {/*<Logo h="24px" iconColor="blue.400" /> */}
        </Box>
        <Box
          visibility={{
            base: "hidden",
            sm: "visible"
          }}
        >
          <Button as="a" colorScheme="orange" href="https://auth.operationshero.com/login">
            Log In
          </Button>
          <IconButton ml="2" icon={isDark ? <FaSun /> : <FaMoon />} isRound="true" onClick={toggleColorMode} color={mode("blue.900", "blue.400")}></IconButton>
        </Box>
      </Flex>
      <NavMenu animate={isOpen ? "open" : "closed"}>
        {links.map((link, idx) =>
          link.children ? (
            <Submenu.Mobile key={idx} link={link} />
          ) : (
            <NavLink.Mobile key={idx} href={link.href}>
              {link.label}
            </NavLink.Mobile>
          )
        )}
        <Button as="a" href="https://auth.operationshero.com/login" w="full" size="lg" mt="5" colorScheme="orange" color={mode("blue.600", "blue.400")}>
          Log in
        </Button>
        <Button colorScheme="blue" w="full" size="lg" mt="5" as="a" href="https://auth.operationshero.com/signup">
          Try for free
        </Button>
      </NavMenu>
    </>
  )
}

const DesktopNavContent = props => {
  const { colorMode, toggleColorMode } = useColorMode()
  const isDark = colorMode === "dark"
  return (
    <Flex className="nav-content__desktop" align="center" justify="space-between" {...props}>
      <Box as="a" href="/" rel="home">
        <VisuallyHidden>OperationsHERO</VisuallyHidden>
        <Image h="12" src={isDark ? OHdark : OHcolor} alt="OperationsHERO" />
        {/*<Logo h="6" iconColor="blue.500" /> */}
      </Box>
      <HStack as="ul" id="nav__primary-menu" aria-label="Main Menu" listStyleType="none">
        {links.map((link, idx) => (
          <Box as="li" key={idx} id={`nav__menuitem-${idx}`}>
            {link.children ? <Submenu.Desktop link={link} /> : <NavLink.Desktop href={link.href}>{link.label}</NavLink.Desktop>}
          </Box>
        ))}
      </HStack>
      <HStack spacing="8" minW="240px" justify="space-between">
        <Button as="a" href="https://auth.operationshero.com/login" target="_blank" colorScheme="orange" fontWeight="bold">
          Log in
        </Button>
        <Button as="a" href="https://auth.operationshero.com/signup" target="_blank" colorScheme="blue" fontWeight="bold">
          Start Free Trial
        </Button>
      </HStack>
      <IconButton icon={isDark ? <FaSun /> : <FaMoon />} isRound="true" onClick={toggleColorMode} color={mode("blue.900", "blue.400")}></IconButton>
    </Flex>
  )
}

export const NavContent = {
  Mobile: MobileNavContext,
  Desktop: DesktopNavContent
}
